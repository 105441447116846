import AppServiceClient from './AppServiceClient';

class ClientServiceNewApi extends AppServiceClient {

  authClient(data){
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'}
    return this.http.post('/auth/connect/token', data, {headers: headers})
  }

  getClientById(id){
    const URL = `/auth/v1/clients/${id}`
    return this.http.get(URL)
  }

  getClients(organizationId){
    return this.http.get(`/auth/v1/clients?organizationId=${organizationId}`)
  }
  
  getCompaniesByOrganization(organizationId){
    return this.http.get(`/auth/v1/clients/organization/${organizationId}/companies/available`)
  }
  
  addClient(data) {
    return this.http.post('/auth/v1/clients', data)
  }

}

export default ClientServiceNewApi;
