// @vendors
import React from 'react'

// @state
import store from '../../redux/store'

// @components
import Dashboard from '../Dashboard';
import PayrollView from './payrollView';

import { LISTROLES } from '../../helpers/ListRoles';

const Payroll = () => {
    const user = store.getState()
    
    return <>{user?.user?.role === LISTROLES.superAdmin || user?.user?.isPayroll ? <PayrollView /> : <Dashboard />}</>;
}

export default Payroll