import { theme } from "../../../assets/theme";

export const EmailRequestStyles = () => ({
	container: {
    padding: '48px',
    '& h3': {
      marginBottom: '20px',
      fontSize: '3rem',
      fontWeight: 700,
    },
  },
  containerText:{
    fontWeight: '400',
    fontSize: '1rem',
    margin: 0,
  },
  buttonSwitchLanguage: {
    display: 'inline-block',
    padding: '10px 20px',
    color: '#000',
    border: '1px solid #000'
  },
  boxItem: {
    marginBottom: '16px',
    '& > p': {
      fontSize: '1.125rem',
      margin: '0 0 12px',
      display: 'block',
    },
  },
	input: {
    width: '100%',
    '& .MuiInputLabel-root': { padding: '0' },
    '& .MuiOutlinedInput-root': {
      height: '56px',
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
          borderWidth: '2px',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #101010',
      },
      '& .MuiInputBase-input': { padding: '12px 20px' },
    },
    '& .MuiInputBase-input': {
      display: 'flex',
      alignItems: 'center',
      padding: '12px',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-10px',
      left: '10px',
      background: '#FFF',
      padding: '0 10px',
      fontSize: '0.875rem',
      margin: '0',
    },
    '> .MuiFormLabel-root.Mui-focused': {
      top: '0',
      color: '#000',
    },
  },
	formButtonWrapper: {
    margin: '48px auto 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    '& .MuiButton-root': {
      height: '56px',
      width: '45%',
      textTransform: 'none',
      backgroundColor: 'rgba(36, 35, 35, 0.3)',
      opacity: '0.5',
      color: '#FFF',
      fontWeight: '600',
      fontSize: '1.25rem',
      boxShadow: 4,
      '&:hover': {
        backgroundColor: 'rgba(36, 35, 35, 0.3)',
        boxShadow: 6,
      },
    },
    '& .MuiButton-root:last-of-type': {
      height: '56px',
      width: '45%',
      backgroundColor: '#000',
      opacity: '1',
      color: '#FFF',
      '&:hover': {
        boxShadow: 12,
      },
    },
  },
})