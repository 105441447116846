/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, {useEffect, useMemo, useState} from 'react';
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Grid,
  Link,
  Paper,
  Toolbar,
} from '@mui/material';
import {useLocation, useParams} from 'react-router-dom';

// @components
import CreateEmployee from '../Employer/components/CreateEmployee/CreateEmployee';
//import AccountCreated from "./AccountCreated";

// @assets
import {MultiQuestStyles} from "../../assets/css/questionnaire-style";
import defaultLogo from '../../assets/images/logoDashboardPublic.svg';

// @constants
import { ROUTENAME } from '../../navigation/RouteName';

// @store
import { useUI } from '../../app/context/ui';

// @helpers
import AppHelper from '../../helpers/AppHelper';

// @services
import EmployeeCompanyServiceNewApi from '../../services/newApi/EmployeeCompanyServiceNewApi';
import { HeaderStyles } from '../../assets/css/general-style';
//import { isValidUrl } from '../../helpers/FunctionsUtils';

// http://localhost:3003
const ExternalEmployer = () => {
  const location = useLocation();
  const {hashToken} = useParams()
  let token = location.search.split('=')[1] ?? '';
  const styles = useMemo(() => MultiQuestStyles(), []);
  const styleHeder = useMemo(() => HeaderStyles(), []);
  const { blockUI, snackbarUI } = useUI();
  const employeeService = useMemo(() => new EmployeeCompanyServiceNewApi(), []);
  const [isRyzeOrganization, setIsRyzeOrganization] = useState(false);
  const [logo, setLogo] = useState(null);
  //const currentUlr = window.location.href;

  if (!token) {
    return null;
  } else {
    token = decodeURIComponent(token);
  }
   
  const getHostCompany = async () => {
    try {
      employeeService.setAccessToken(token)
      blockUI.current.open(true);
      const response = await employeeService.getCompanyApiHost(hashToken, token);
      //redirectUrl(response?.data?.homeUrl);
      setIsRyzeOrganization(response?.data?.customWorkflow);
      setLogo(response?.data?.logo ?? defaultLogo);
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }
  // http://localhost:3003/company/d4c08fe0-d02d-45b7-9085-3af9e490ef66/form-validate?tkn=a6db48ab-05cb-4d60-b3f0-416566b91f04
  //https://rockerbox-dev.rockerbox.tech/company/d4c08fe0-d02d-45b7-9085-3af9e490ef66/form-validate?tkn=a6db48ab-05cb-4d60-b3f0-416566b91f04
  /* const redirectUrl = (url) => {
    const isValid = isValidUrl(url);
    if(!isValid)return;
    const browserUrl = new URL(currentUlr)
    const orgUrl = new URL(url)
    const isSameUrl = browserUrl.origin === orgUrl.origin;
    if(isSameUrl)return;
    const navigateToUrl = `${orgUrl.origin}${browserUrl.pathname}${browserUrl.search}`;
    return window.location.href = navigateToUrl;
  } */
  
  useEffect(() => {
    if(token)getHostCompany();
  }, [token])
  
  return (
    <Box>
       <>
      <CssBaseline />
      <AppBar position='fixed' sx={styleHeder.appBar}>
        <Toolbar sx={{...styleHeder.toolbar, paddingTop: '30px'}}>
          <Grid sx={styleHeder.containerImg}>
            <Box sx={styleHeder.companyLogo}>
              <Link to={{pathname: ROUTENAME.dashboard}}>
              {logo && <img src={logo} alt='logo' className="imageHeader"/>}
              </Link>
            </Box>
          </Grid>
        
        </Toolbar>
      </AppBar>
    </>
      <Container>
        <Paper elevation={4} sx={styles.paper}>
          <CreateEmployee
            token={token}
            isRyze={isRyzeOrganization}
          />
        </Paper>
      </Container>
    </Box>
  );
};

export default ExternalEmployer;
