// @vendors
import React, { useContext, useMemo, useState } from 'react'
//import { Button, Grid, TextField } from '@mui/material'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Button,
  ClearAllIcon,
  IconButton,
  TextField,
  Tooltip,
  Grid,
} from "../../../../components/shared/MaterialUI";

// @assets
import { EmployerStyles } from '../../../../assets/css/employer-style';
import { EmployeeTableStyles } from '../../../../assets/css/employeeTable-style';

// @context
import { EmployeeContext } from '../../Employer';

// @constants
import { ROUTENAME } from '../../../../navigation/RouteName';

// @components
import MenuAddBranch from './MenuAddBranch';

// @helpers
import { permissionsForEditEmployer } from '../../../../helpers/FunctionsUtils';

const ToolbarChild = () => {
    const styles = useMemo(() => EmployerStyles(), []);
    const styleEmployee = useMemo(() => EmployeeTableStyles(),[]);
    const history = useHistory();
    const {
        setShowChildrens,
        getAllEmployesByCompany,
        getAllChildrens,
        employerDetails,
        setRowsState,
        companyId,
        listChildrens,
        setListChildrens,
        setTextEmptyChild,
        setSearchChildren,
        searchChildren
    } = useContext(EmployeeContext);
    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const openMenu = Boolean(anchorElMenu);
    const user = useSelector((loadedState) => loadedState.user);

    const handleClickMenu = (event) => {
        setAnchorElMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };

    const showEmployeesByCompany = () => {
        clearSearch();
        setRowsState((prev) => ({
            ...prev,
            pageSize: 10,
            rows: [],
            rowCount: 0,
            currentPage: 0,
            page: 0,
            filters: { name: '', status: '' },
        }));
        setShowChildrens(false);
        getAllEmployesByCompany({companyId:companyId, filters: {}});
    }

    /* search bar */

    const clearSearch = () => {
        setSearchChildren('');
        setTextEmptyChild(false);
        setListChildrens({...listChildrens, rows: listChildrens?.rowList});
    }

    const onChangeSearch = (e) => {
        setTextEmptyChild(false);
        const value = e.target.value;
        const transformValue = value?.toLowerCase();
        const list = listChildrens?.rowList?.filter((item) =>  item?.name?.toLowerCase()?.includes(transformValue));
        setSearchChildren(value);
        if(!list.length) setTextEmptyChild(true);
        setListChildrens((prev) => ({...prev, rows: list }));
    }

  return (
    <Grid
        sx={styles.toolbarChilds}
        container
    >
        <h2 style={{textAlign: 'start', fontSize: '1.5rem'}} >
            Children list
        </h2>

        <Grid sx={styles.containerSearch}>
            <TextField
                sx={{...styleEmployee.searchInput, ...styleEmployee.btnStyleSm}}
                value={searchChildren}
                id="searchbar"
                placeholder="Search"
                onChange={(e) => onChangeSearch(e)}
                variant="outlined"
            />
            <Tooltip title="Clear filter">
                <IconButton
                    onClick={() => clearSearch()}
                    sx={{width: '40px', height: '40px'}}
                >
                    <ClearAllIcon />
                </IconButton>
            </Tooltip>
        </Grid>

        {permissionsForEditEmployer(user?.role) &&
            <Button
                variant="contained"
                sx={styles.btnShowEmployees}
                onClick={handleClickMenu}
                aria-controls={openMenu ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
            >
                Add new branch
            </Button>
        }

        {employerDetails?.data?.mainParentCompanyId
            ?   <Button
                    variant='contained'
                    sx={styles.btnShowEmployees}
                    onClick={() => {
                        clearSearch();
                        history.push(`${ROUTENAME.employer}${employerDetails?.data?.mainParentCompanyId}`);
                        getAllChildrens(employerDetails.data.mainParentCompanyId);
                    }}
                >
                    back to main parent
                </Button>
            :   null
        }
        
        {employerDetails?.data?.parentCompanyId
            ?   <Button
                    variant='contained'
                    sx={styles.btnShowEmployees}
                    onClick={() => {
                        clearSearch();
                        history.push(`${ROUTENAME.employer}${employerDetails?.data?.parentCompanyId}`)
                        getAllChildrens(employerDetails.data.parentCompanyId)
                    }}
                >
                    back to parent
                </Button>
            :   null
        }

        <Button
            variant='contained'
            sx={styles.btnShowEmployees}
            onClick={() => showEmployeesByCompany()}
        >
            show employees
        </Button>

        <MenuAddBranch 
            anchorElMenu={anchorElMenu}
            openMenu={openMenu}
            handleCloseMenu={handleCloseMenu}
        />
    </Grid>
  )
}

export default ToolbarChild