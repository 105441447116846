/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useEffect, useMemo, useState } from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

// @assets
import { GeneralStyles } from '../../../assets/css/general-style';
import { CreateEmployeeStyles } from '../../../assets/css/createEmployee-style';

// @componenst
import { AppTextField } from '../../../components/forms';

// @context
import { useUI } from '../../../app/context/ui';

// @helpers
import AppHelper from '../../../helpers/AppHelper';

// @service
import TicketService from '../../../services/newApi/TicketServiceNewApi';

const ResponseDialog = ({open, handleCloseModal, id}) => {
    const { blockUI, snackbarUI } = useUI();
    const generalStyle =  useMemo(() => GeneralStyles(), []);
    const styleForm = useMemo(() => CreateEmployeeStyles(), []);
    const ticketService = useMemo(() => new TicketService(),[]);
    const [responseDev, setResponseDev] = useState({responseDev: ''});
    
    const validationEmployee = Yup.object({
        responseDev: Yup.string('Enter your response')
            .required('Response is required')
            .nullable()
            .typeError(`Response can't be empty`),
    });

    const onClose = () => {
        setResponseDev({responseDev:''});
        handleCloseModal();
    }

    const onSubmit = (values) => {
        const OBJ = {
            devResponse : values?.responseDev,
        };
        handleSubmit(OBJ);
    }

    const handleSubmit = async (info) => {
        try {
            blockUI.current?.open(true);
            await ticketService.updateResponseDev(id, info);
            onClose();
            blockUI.current?.open(false);
        } catch (error) {
            AppHelper.checkError(error, snackbarUI);
            blockUI.current?.open(false);
        }
    }

    const getTicketById = async (params) => {
        try {
          blockUI.current?.open(true);
          const response = await ticketService.getTicketDetail(id);
          setResponseDev({responseDev: response?.data?.data?.responseFromDev ?? ''});
          blockUI.current?.open(false);
        } catch (error) {
            AppHelper.checkError(error, snackbarUI);
            blockUI.current?.open(false);
        }
    }

    useEffect(() => {
        if(open)getTicketById()
    }, [open])

  return (
    <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        sx={generalStyle.dialog.dialogBox}
    >
        <DialogTitle
            textAlign="center"
            sx={generalStyle.dialog.title}
        >
            Response from devs
        </DialogTitle>
        <DialogContent>
            <Grid sx={generalStyle.containerFormOrganization} >
                <Grid
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        margin: '0 0 30px 0'
                    }}
                >
                </Grid>
                <Formik
                    initialValues={responseDev}
                    validationSchema={validationEmployee}
                    onSubmit={(values) => { onSubmit(values) }}
                    enableReinitialize={true}
                >
                    {(props) => {
                        return (
                            <Form autoComplete="off" className="formCreateUser">
                                <Box sx={{...styleForm.inputsCtn, gridGap: '10px',}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Box sx={{...styleForm.boxItem}}>
                                                <p>Response: <span style={{color: "red"}}>*</span> </p>
                                                <AppTextField
                                                    sx={styleForm.input}
                                                    label="Type your response here"
                                                    variant="outlined"
                                                    value={responseDev.responseDev}
                                                    onChange={(e) => setResponseDev({responseDev: e.target.value})}
                                                    id="responseDev"
                                                    name="responseDev"
                                                    multiline
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={styleForm.formButtonWrapper}>
                                    <Button
                                        color="inherit"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit">Save</Button>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Grid>
        </DialogContent>
    </Dialog>
  )
}

export default ResponseDialog