// @pages
import CreateEmployer from '../pages/Admin/CreateEmployer/CreateEmployer';
import Login from '../pages/auth/LoginPage';
import Dashboard from '../pages/Dashboard';
import Employer from '../pages/Employer/Employer';
import QuestionnaireResults from '../pages/Employer/components/EmployeeTable/components/QuestionnaireResults';
import Settings from '../pages/Admin/Settings';
import EmployeeForms from '../pages/Employer/components/EmployeeForms/EmployeeForms';
import ApiClients from '../pages/ApiClient/ApiClients';
import Payroll from '../pages/payroll/payroll';
import SearchEmployees from '../pages/searchEmployer/SearchEmployees';
import OrganizationScreen from '../pages/Organization/OrganizationScreen';
import UserManagement from '../pages/userManagement/UserManagement';
import AddUser from '../pages/userManagement/components/AddUser';
import Manuals from '../pages/Manuals/Manuals';
import TicketManagement from '../pages/ticket/TicketManagement';

// @constants
import { ROUTENAME } from './RouteName';
import CreateTicket from '../pages/ticket/components/CreateTicket';

const Routes = [
  {
    path: ROUTENAME.mainRoute,
    name: 'login',
    exact: true,
    pageTitle: 'Login',
    component: Login,
  },
  {
    path: ROUTENAME.dashboard,
    name: '',
    exact: true,
    pageTitle: 'Dashboard',
    component: Dashboard,
  },
  {
    path: ROUTENAME.createEmployer,
    name: '',
    exact: true,
    pageTitle: 'Create Employer',
    component: CreateEmployer,
  },
  {
    path: `${ROUTENAME.editEmployer}:id`,
    name: '',
    exact: true,
    pageTitle: 'Edit Employer',
    component: CreateEmployer,
  },
  {
    path: `${ROUTENAME.employer}:id`,
    name: '',
    exact: true,
    pageTitle: 'Employer',
    component: Employer,
  },
  {
    path: `${ROUTENAME.results}:id`,
    name: '',
    exact: true,
    pageTitle: 'Questionnaire Results',
    component: QuestionnaireResults,
  },
  {
    path: ROUTENAME.settings,
    name: '',
    exact: true,
    pageTitle: 'Admin Settings',
    component: Settings,
  },
  {
    path: `${ROUTENAME.forms}:id`,
    name: '',
    exact: true,
    pageTitle: 'Forms',
    component: EmployeeForms,
  },
  {
    path: ROUTENAME.apiClients,
    name: '',
    exact: true,
    pageTitle: 'Api Clients',
    component: ApiClients,
  },
  {
    path: ROUTENAME.payroll,
    name: '',
    exact: true,
    pageTitle: 'Payroll',
    component: Payroll,
  },
  {
    path: ROUTENAME.searchEmployees,
    name: '',
    exact: true,
    pageTitle: 'Search employees',
    component: SearchEmployees,
  },
  {
    path: ROUTENAME.organizations,
    name: '',
    exact: true,
    pageTitle: 'Organization Screen',
    component: OrganizationScreen,
  },
  {
    path: ROUTENAME.userManagement,
    name: '',
    exact: true,
    pageTitle: 'User management Screen',
    component: UserManagement,
  },
  {
    path: ROUTENAME.addUser,
    name: '',
    exact: true,
    pageTitle: 'Add user Screen',
    component: AddUser,
  },
  {
    path: `${ROUTENAME.editUser}/:id`,
    name: '',
    exact: true,
    pageTitle: 'Edit user Screen',
    component: AddUser,
  },
  {
    path: ROUTENAME.manuals,
    name: '',
    exact: true,
    pageTitle: 'Manuals Screen',
    component: Manuals,
  },
  {
    path: ROUTENAME.ticket,
    name: '',
    exact: true,
    pageTitle: 'Ticket Screen',
    component: TicketManagement,
  },
  {
    path: ROUTENAME.createTicket,
    name: '',
    exact: true,
    pageTitle: 'Create Ticket Screen',
    component: CreateTicket,
  },
  {
    path: `${ROUTENAME.editTicket}/:ticketId`,
    name: '',
    exact: true,
    pageTitle: 'Edit Ticket Screen',
    component: CreateTicket,
  },
];

export default Routes;
