import AppServiceNewApi from "./AppServiceNewApi";

class QuestStartServiceNewApi extends AppServiceNewApi{

    constructor() {
        super();
        this.path = "/api/internal/v1/questionnaire";
    }

    questStartNewApi(hash, lang, employerId){
        const URL = employerId ? `${this.path}/start?hash=${hash}&lang=${lang}&employerId=${employerId}` : `${this.path}/start?hash=${hash}&lang=${lang}`
        return this.http.post(URL)
    }
    
    questStartNextNewApi(body, hash, lang){
        const URL = `${this.path}/employee-quest/next?hash=${hash}&lang=${lang}`
        return this.http.post(URL, body)
    }

    questNext(body, hash, lang, isEdit){
        const URL = isEdit ? `${this.path}/next?hash=${hash}&lang=${lang}&edit=${isEdit}` : `${this.path}/next?hash=${hash}&lang=${lang}`
        return this.http.post(URL, body)
    }
    
    questPrev(hash, questionId, groupId, isEdit){
        const URL = isEdit ? `${this.path}/prev?hash=${hash}&questionId=${questionId}&groupId=${groupId}&edit=${isEdit}` : `${this.path}/prev?hash=${hash}&questionId=${questionId}&groupId=${groupId}`
        return this.http.post(URL)
    }

    validateSsnDigitalSignature(hash, ssn){
        const URL = `${this.path}/ssn?hash=${hash}&ssn=${ssn}`
        return this.http.get(URL)
    }

    getAnswersByEmployee(id) {
        return this.http.get(`${this.path}/answers?employeeId=${id}`);
    }

    callWebhook(hash) {
        return this.http.post(`${this.path}/call-webhook?hash=${hash}`);
    }

    callCompanyLogo(hash) {
        return this.http.get(`${this.path}/company/logo?hash=${hash}`);
    }

    saveDd214(hash, answer){
        return this.http.post(`${this.path}/have/dd214?hash=${hash}&answer=${answer}`)
    }

    uploadFile(hash, file){
        return this.http.post(`${this.path}/upload/dd214?hash=${hash}`, file)
    }
    
    uploadDriverLicense(hash, file){
        return this.http.post(`${this.path}/upload/driver_license?hash=${hash}`, file)
    }
    
    withoutLicense(hash, answer){
        //let encodedStr = encodeURIComponent(answer);
        return this.http.post(`${this.path}/have/driver_license?hash=${hash}&answer=${answer}`, {})
    }

}

export default QuestStartServiceNewApi