// @vendors
import React from 'react'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Grid, Paper, Typography } from '@mui/material'
import { useParams, useHistory } from 'react-router-dom';
import { Box } from '@mui/system';

// @assets
import { EmployerStyles } from '../../../assets/css/employer-style';
import { Styles } from '../../../assets/css/questionnaire-style';
import logo from '../../../assets/images/logoDashboardPublic.svg';

// @context
import { useUI } from '../../../app/context/ui';

// @constants
import { formDataUploadEmployees } from '../../../helpers/FunctionsUtils';
import AppHelper from '../../../helpers/AppHelper';

// @services
import QuestionnaireServiceNewApi from '../../../services/newApi/QuestionnaireServiceNewApi';

const UploadDd214 = () => {
  const styles = EmployerStyles();
  const stylesQuest = Styles();
  const { blockUI, snackbarUI } = useUI();
  const { hash } = useParams();
  const newQuestionService = new QuestionnaireServiceNewApi();
  const history = useHistory();

  const handleClickFile = () => {
    const file = document.getElementById('upload-input-file')
    file?.click()
  }

  const uploadFile = async (e) => {
    if (e.target.files[0]) {
      const file = formDataUploadEmployees(e.target.files[0]);
      try {
        blockUI.current.open(true);
        await newQuestionService.uploadFile(hash, file);
        blockUI.current.open(false);
        return history.push(`/confirmation-code-answer/${hash}`)
      } catch (err) {
        blockUI.current.open(false);
        AppHelper.checkError(err, snackbarUI);
      }
    }
  };

  return (
    <>
      <Box sx={stylesQuest.logo}>
        <img src={logo} alt='dashboard public' width='250px' />
      </Box>
      <Paper elevation={4} sx={{margin: '100px 50px 0px'}}>
          <Grid sx={styles.containerDownload}>
              <Typography variant="h5" gutterBottom>Upload document</Typography>
              <Grid sx={styles.sectionToDownload}>
                <CloudUploadOutlinedIcon sx={{
                    fontSize:'50px',
                    color: '#000'
                  }}
                  onClick={() => handleClickFile()}
                />
                <input
                  type='file'
                  accept="image/png, image/jpeg, .docx, .pdf"
                  id='upload-input-file'
                  style={{display: 'none'}}
                  onChange={(e) => uploadFile(e)}
                />
              </Grid>
          </Grid>
      </Paper>
    </>
  )
}

export default UploadDd214