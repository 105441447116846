// @vendors
import React, { useContext, useMemo, useState } from 'react'
import sx from "mui-sx";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

// @components material
import {
    Box,
    Button,
    ButtonGroup,
    EditIcon,
    ExpandMoreIcon,
    ExpandMoreRoundedIcon,
    IconButton,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    Tooltip,
} from '../../../../components/shared/MaterialUI';

// @assets
import { EmployeeTableStyles } from '../../../../assets/css/employeeTable-style';
import { DashboardStyles } from '../../../../assets/css/dashboard-style';
import { MenuProps } from '../../../../assets/css/tableComponents-styles';

// @helpers
import { permissionsForEditEmployer } from '../../../../helpers/FunctionsUtils';
import AppHelper from '../../../../helpers/AppHelper';

// @constants
import { ROUTENAME } from '../../../../navigation/RouteName';

// @context
import { useUI } from '../../../../app/context/ui';
import { EmployeeContext } from '../../Employer';

// @services
import CompanyServiceNewApi from '../../../../services/newApi/CompanyServiceNewApi';
import CollapseChild from './CollapseChild';

const ChildrenRow = ({row}) => {
  const styles = useMemo(() => EmployeeTableStyles(), []);
  const styleIcon = useMemo(() => DashboardStyles(), []);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state?.user);
  const { blockUI, snackbarUI } = useUI();
  const companyService = useMemo(() => new CompanyServiceNewApi(), []);
  const {
      listChildrens,
      setListChildrens,
      getAllChildrens,
      companyId,
      setSearchChildren,
  } = useContext(EmployeeContext);
  const history = useHistory();
  let statusOptions = ["active", "suspended", "inactive"];
  const [listChildrenByFather, setListChildrenByFather] = useState([]);

  const handleOpen = async () => {
    if(row?.generation === 5)return;
    setOpen(!open);
    if(!open)callChildrens();
  };

  const callChildrens = async () => {
      try {
        blockUI.current.open(true);
        const response = await companyService.getChildsByCompany(row?.id);
        const data = response?.data?.data;
        setListChildrenByFather(data)
        blockUI.current.open(false);
      } catch (e) {
        blockUI.current.open(false);
        AppHelper.checkError(e, snackbarUI);
      }
  };

  const onStatusChange = async (e, row) => {
    try {
      blockUI.current.open(true);
      await companyService.updateCompanyStatus(row?.id, e.target.value);
      let newRows = listChildrens.rows.map((r) => 
        (r.id === row?.id ? { ...r, status: e.target.value } : r)
      );
      setListChildrens({...listChildrens, rows: newRows});
      getAllChildrens(companyId)
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }    
  };

  return (
    <>
      <TableRow>
        <TableCell
          scope="row"
          sx={sx(
            styles.tableCell,
            { condition: open, sx: styles.borderNone },
            { condition: !open, sx: styles.bottomBorder }
          )}
        >
          <IconButton onClick={handleOpen}>
            <ExpandMoreIcon />
          </IconButton>
        </TableCell>
        
        <TableCell
          scope="row"
          sx={sx(
            styles.tableCell,
            {textAlign: 'left'},
            { condition: open, sx: styles.borderNone },
            { condition: !open, sx: styles.bottomBorder }
          )}
        >
          <Box
              onClick={() => {
                setSearchChildren('');
                history.push(`${ROUTENAME.employer}${row.id}`);
                getAllChildrens(row.id);
              }}
              sx={{
                  ...styleIcon.employerLink,
                paddingLeft: 0,
                display: 'inline-block'
                }}
            >
              {row?.displayName ? row?.displayName : row?.name}
            </Box>
        </TableCell>

        <TableCell
          scope="row"
          sx={sx(
            styles.tableCell,
            { condition: open, sx: styles.borderNone },
            { condition: !open, sx: styles.bottomBorder }
          )}
        >
            <Select
                value={row.status}
                IconComponent={ExpandMoreRoundedIcon}
                onChange={(e) => onStatusChange(e, row)}
                sx={{
                    ...styleIcon.statusSelect,
                    width: '100%',
                    pointerEvents: permissionsForEditEmployer(user?.role) ? 'auto' : 'none',
                }}
                MenuProps={MenuProps}
            >
                {statusOptions.map((st, index) => (
                    <MenuItem
                        value={st}
                        key={index}
                        disabled={st === row.status}
                        sx={{ textTransform: "capitalize" }}
                    >
                        {st}
                    </MenuItem>
                ))}
            </Select>
        </TableCell>

        <TableCell
          scope="row"
          sx={sx(
            styles.tableCell,
            styles.tableColumnHide,
            { condition: open, sx: styles.borderNone },
            { condition: !open, sx: styles.bottomBorder }
          )}
        >
          {
            permissionsForEditEmployer(user?.role)
            ? <ButtonGroup
                variant="contained"
                size="medium"
                sx={styleIcon.buttonTools}
                fullWidth={true}
            >
                <Tooltip
                title={<p style={{ fontSize: "16px", margin: "0" }}>Edit child</p>}
                arrow
                >
                <Link
                    to={{
                        pathname: `${ROUTENAME.editEmployer}${row?.id}`,
                        state: { page: 1 },
                    }}
                >
                    <Button
                        variant="contained"
                        style={{ backgroundColor: "transparent" }}
                        children={<EditIcon />}
                    />
                </Link>
                </Tooltip>
            </ButtonGroup>
            : null
          }
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, width: '100%'}}
            sx={sx(
                styles.tableCell,
                { padding: "0"},
                { condition: !open, sx: styles.borderNone },
                { condition: open, sx: styles.bottomBorder }
            )}
            colSpan={12}
        >
            <CollapseChild
                open={open}
                children={listChildrenByFather}
            />
        </TableCell>
      </TableRow>
    </>
  )
}

export default ChildrenRow