/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useMemo, useState} from 'react';

// @state
import { useUI } from '../../../../../app/context/ui';

// @assets
import { DashboardStyles } from '../../../../../assets/css/dashboard-style';

// @material-ui
import { AddIcon, Button } from '../../../../../components/shared/MaterialUI';

// @components
import { AddNewEmployeeModal } from './AddNewEmployeeModal';

// @helpers
import AppHelper from '../../../../../helpers/AppHelper';

// @services
import CompanyServiceNewApi from '../../../../../services/newApi/CompanyServiceNewApi';

export const AddNewEmployee = (props) => {
  const { companyId } = props;
  const styles = useMemo(() => DashboardStyles(), []);
  const { dialogUI, snackbarUI } = useUI();
  const [openModal, setOpenModal] = useState(false);
  const [qrValue, setQrValue] = useState('');
  const companyServiceNewApi = useMemo(() => new CompanyServiceNewApi(), []);  

  const onClose = () => {
    setQrValue('');
    setOpenModal(false);
  }

  const getCodeGenerate = async () => {
    try {
      const resp = await companyServiceNewApi.codeGenerateLink(companyId);
      const data = resp?.data?.data?.message;
      verifyIfLinkIsAxiscare(data);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };
  
  const verifyIfLinkIsAxiscare = (data) => {
    if(data?.toString()?.includes('axiscare')){
      const changeValue = data?.toString()?.replace('axiscare', 'app');
      setQrValue(changeValue);
      return;
    }
    return setQrValue(data);
  }

  const onNewEmployee = async () => {
    setOpenModal(false);
    const settings = {
      getEmployees: props.getEmployees,
      companyId: props.companyId,
      hideExternalId: props.hideExternalId,
      styles: {
        maxWidth: 'lg',
        content: {
          padding: '2rem',
          '::-webkit-scrollbar': {
            width: '10px',
          },
          '::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'rgba(16, 16, 16, 0.5)',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        },
      },
    };
    dialogUI.current.open('', '', settings);
  };

  const onImageDownload = (svgId) => {
    const svg = document.getElementById(svgId);
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <>
      <Button
        variant='outlined'
        endIcon={<AddIcon />}
        sx={styles.button}
        onClick={() => {
          getCodeGenerate();
          setOpenModal(true);
        }}
      >
        Add New Employee
      </Button>
      <AddNewEmployeeModal 
        open={openModal} 
        onClose={onClose} 
        onNewEmployee={onNewEmployee}
        onImageDownload={onImageDownload}
        qrValue={qrValue}
        companyId={companyId}
      />
    </>
  );
};
