export const ROUTENAME = {
    mainRoute: '/',
    login: '/login',
    dashboard: '/dashboard',
    createEmployer: '/create-employer',
    settings: '/settings',
    apiClients: '/api-client',
    payroll: '/payroll',
    searchEmployees: '/search-employees',
    organizations: '/organizations',
    userManagement: '/user-management',
    addUser: '/user-management/add',
    editUser: '/user-management/edit',
    forms: /forms/,
    results: '/results/',
    employer: '/employer/',
    editEmployer: '/edit-employer/',
    error: '/error',
    logout: '/logout',
    home: '/home',
    aboutUs: '/aboutUs',
    services: '/services',
    contacts: '/contacts',
    recoverPassword: '/recover-password',
    resetPassword: '/reset-password',
    ob365: '/ob365',
    manuals: '/manuals',
    ticket: '/ticket-management',
    createTicket: '/create-ticket',
    editTicket: '/edit-ticket',
}