// @vendors
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  TextField,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';

// @style
import { EmailRequestStyles } from './EmailRequest.styles';

// @services
import OB365ServiceNewApi from '../../../services/newApi/Ob365ServiceNewApi';

// @context
import { useUI } from '../../../app/context/ui';

// @components
import Header from '../../../components/Header';

// @helpers
import AppHelper from '../../../helpers/AppHelper';
import { ob365En, ob365Es } from '../../../helpers/FunctionsUtils';

// http://localhost:3003/ob365?lang=es&email=ndiaz+1@techgenies.com

const EmailRequest = () => {
  const open = true;
  const location = useLocation();
	const styles = useMemo(() => EmailRequestStyles(), []);
  const { blockUI, snackbarUI } = useUI();
  const ob365Service = useMemo(() => new OB365ServiceNewApi(), []);
  const queryParams = new URLSearchParams(location?.search);
  const employeeEmail = queryParams.get('email');
  const ENCODEMAIL = employeeEmail ? decodeURIComponent(employeeEmail.replace(/ /g, '%2B')) : '';
  const [logo, setLogo] = useState('');
  const [lang, setLang] = useState('en');
  const [employeeValues, setEmployeeValues] = useState({
    email: ENCODEMAIL ?? '',
  });

  const getLogo = async () => {
    try {
      blockUI.current.open(true);
      const response = await ob365Service.getLogoOb()
      setLogo(response?.data?.data?.logo)
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  useEffect(() => {
    getLogo()
    if(queryParams.get('lang'))setLang(queryParams.get('lang'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

	const onSubmit = async (values) => {
		try {
      blockUI.current.open(true);
      const res = await ob365Service.validateEmail(values);
      if (res?.data?.data?.message.includes('http')) {
        const url = `${res?.data?.data?.message}?lang=${lang}`
        window.location.href = url;
        blockUI.current.open(false);
        return;
      }
      snackbarUI.current.open(res?.data?.data?.message, 'error');
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
	};

	const handleChange = (e) => {
		setEmployeeValues({ ...employeeValues, [e.target.name]: e.target.value });
	};

	const validationSchema = Yup.object().shape({
		email: Yup.string('Enter your email address')
		.email('Invalid email')
		.required('Email is required'),
	});
 
  return (
    <Box>
      <Header title='OB365' companyLogo={logo} />
			<Dialog
			fullWidth={true}
			maxWidth={'lg'}
			open={open}
      sx={{marginTop: '50px',}}
		>
      <Container component='div' maxWidth={false} sx={styles.container}>
        {/* <Typography variant='h3'>{lang === 'es' ? ob365Es.welcome :  ob365En.welcome}</Typography> */}

        <Grid sx={{margin: '0px 0px 20px 0px'}}>
          <Button
            variant='outlined'
            onClick={() => setLang(prev => prev === 'es' ? 'en' : 'es')}
            sx={styles.buttonSwitchLanguage}
          >
            {lang === 'es' ? 'view in english' : 'ver en español'}
          </Button>
        </Grid>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '20px'}}>
          <p style={styles.containerText}>{lang === 'es' ? ob365Es.initialText : ob365En.initialText}</p>
          <p style={styles.containerText}>{lang === 'es' ? ob365Es.nextText : ob365En.nextText}</p>
          <p style={styles.containerText}>{lang === 'es' ? ob365Es.nextTextOne : ob365En.nextTextOne}</p>
          <p style={styles.containerText}>{lang === 'es' ? ob365Es.nextTextTwo : ob365En.nextTextTwo}</p>
          <p style={styles.containerText}>{lang === 'es' ? ob365Es.nextTextThree : ob365En.nextTextThree}</p>
        </Box>
        <Formik
          initialValues={employeeValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          enableReinitialize={true}
        >
          {(props) => {
            const { values, touched, errors, handleBlur } = props;
            return (
              <Form autoComplete='off' className='formCreateUser'>
								<Box sx={styles.boxItem}>
                    {/* <p>{lang === 'es' ? ob365Es.question : ob365En.question}</p> */}
                    <TextField
                      sx={styles.input}
                      label={lang === 'es' ? 'ejemplo@correo.com' : 'example@email.com'}
                      variant='outlined'
                      autoComplete='new-password'
                      id='email'
                      name='email'
                      type='email'
                      defaultValue={values.email}
											onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={errors.email && touched.email ? errors.email : ''}
                      //disabled={ENCODEMAIL ? true : false}
                    />
                	</Box>
                <Box sx={styles.formButtonWrapper}>
                  <Button type='submit'>{lang === 'es' ? 'Siguiente' : 'Submit'}</Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Dialog>
    </Box>
  );
};

export default EmailRequest;