import moment from 'moment';

import { styled } from "@mui/material/styles";
import { Tooltip, tooltipClasses } from '@mui/material';

import AppHelper from './AppHelper';
import { LISTROLES } from './ListRoles';

import OrganizationServiceNewApi from '../services/newApi/OrganizationService';
import AuthServiceNewApi from '../services/newApi/AuthServiceNewApi';

export const formDataUploadEmployees = (file) => {
  const formdata = new FormData();
  formdata.append('file', file);
  return formdata;
};

export const formattDate = (date) => {
  if (date) return moment(date).format('DD-MM-YYYY');
  return '';
};

export const validateEmptyString = (text) => {
  if (text === null) return '';
  else return text;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuItemProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const targetGroupCode = [
  { id: 1, name: 'Bc' },
  { id: 2, name: 'Ba' },
  { id: 3, name: 'Ab' },
  { id: 4, name: 'Cd' },
  { id: 5, name: 'Ad' },
  { id: 6, name: 'Bb' }
];

export const convertDateTolocaleTime = (date) => {
  if(!date)return;
  return Intl.DateTimeFormat(navigator.language || navigator.userLanguage, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).format(new Date(`${date} ${date.includes(':') ? '' : '12:00:00'} UTC`));
};

export const convertDateToUTCTexas = (date, language = navigator.language || navigator.userLanguage) => {
  if(!date)return;
  return new Intl.DateTimeFormat(language, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "America/Chicago",
  }).format(new Date(`${date} ${date.includes(":") ? "" : "12:00:00"} UTC`));
};

export const convertDateToNewFormat = (date) => {
  const splitDate = date.split('/');
  const day = splitDate[0];
  const month = splitDate[1];
  const year = splitDate[2];
  return `${month}/${day}/${year}`
}

export const ob365En = {
  initialText: 'Congratulations! You have successfully completed the onboarding process.',
  nextText: 'We now ask you to take a few minutes to complete the Work Opportunity Tax Credit (WOTC) questionnaire. ',
  nextTextOne: 'This questionnaire helps determine eligibility for a federal tax credit program that may provide benefits for both you and the company.',
  nextTextTwo: 'All information you provide is kept strictly confidential and will not affect your job, wages, or taxes in any way.',
  nextTextThree: 'Thank you for your participation!', 
  question: 'Please provide the email you used in your onboarding process.',
}

export const ob365Es = {
  initialText: '¡Felicitaciones! Ha completado con éxito el proceso de incorporación.',
  nextText: 'Le pedimos que se tome unos minutos para completar el cuestionario de Work Opportunity Tax Credit (WOTC - Crédito Fiscal por Oportunidades de Trabajo).',
  nextTextOne: 'Este cuestionario ayuda a determinar si cumple con los requisitos para un programa federal de crédito fiscal que podría brindarle beneficios tanto a usted como a la empresa.',
  nextTextTwo: 'Toda la información que proporcione se mantendrá estrictamente confidencial y no afectará su trabajo, salario ni impuestos de ninguna manera.',
  nextTextThree: '¡Gracias por su participación!',
  question: 'Proporcione el correo electrónico que utilizó en su proceso de incorporación.',
};

//download templete for update employers or create employees
export const downloadTemplate = async (data, name) => {
  let url = window.URL.createObjectURL(
    new Blob([data], { type: "text/csv" })
  );
  let a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.click();
};

export const getOrganizations = async (snackbarUI, blockUI) => {
  const organizationService = new OrganizationServiceNewApi();
  try {
    blockUI.current.open(true);
    const response = await organizationService.getOrganization();
    blockUI.current.open(false);
    return response;
  } catch (error) {
    blockUI.current.open(false);
    AppHelper.checkError(error, snackbarUI);
  }
}

export const redirectToRockerbox = (currentUrl) => {
  //if(currentUrl?.includes('axiscare'))return window.location.href  = 'https://app.rockerbox.tech/login';
  if(currentUrl?.includes('axiscare')){
    if(currentUrl?.includes('stg'))return locationHref(currentUrl, 'rockerbox');
    return locationHref(currentUrl, 'app');
  }
  return;
}

const locationHref = (currentUrl, string) => {
  const url = currentUrl?.replace('axiscare', string);
  return window.location.replace(url);
  //return window.location.href  = url;
}

export const permissionsForAddEmployer = (role) => {
  if(role === LISTROLES.superAdmin)return true;
  if(role === LISTROLES.orgManager)return true;
  if(role === LISTROLES.orgReporter)return true;
  return false;
}

export const permissionsForEditEmployer = (role) => {
  if(role === LISTROLES.superAdmin)return true;
  if(role === LISTROLES.orgManager)return true;
  if(role === LISTROLES.orgReporter)return true;
  if(role === LISTROLES.employerManager)return true;
  return false;
}

export const permissionsForAddOrEditEmployees = (role) => {
  if(role === LISTROLES.superAdmin)return true;
  if(role === LISTROLES.orgManager)return true;
  if(role === LISTROLES.orgReporter)return true;
  if(role === LISTROLES.orgProcessor)return true;
  if(role === LISTROLES.employerManager)return true;
  if(role === LISTROLES.employerReporter)return true;
  return false;
}

export const permissionsForOrganization = (role) => {
  if(role === LISTROLES.orgManager ||
    role === LISTROLES.orgReporter ||
    role === LISTROLES.orgReader ||
    role === LISTROLES.orgProcessor
  )return true;
  return false;
}

export const getOrganizationWithoutToken = async (blockUI, snackbarUI) => {
  const authService = new AuthServiceNewApi();
  try {
    blockUI.current.open(true);
    const response = await authService.getOrganizations();
    const logo = await logoOrganization(response?.data?.data);
    return logo;
  } catch (error) {
    blockUI.current.open(false);
    AppHelper.checkError(error, snackbarUI);
  }
}

const logoOrganization = async (resp) => {
  const location = window.location.href;
  const currentUrl = location.toString();
  const stringToReplace = currentUrl.includes('login') ? '/login' : '/home';
  const url = currentUrl.replace(stringToReplace, '')
  const logo = resp?.find((item) => item?.homeUrl.includes(url))
  return logo?.logo ? logo?.logo : null;
}

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} key={Math.random()}/>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export const getTargetGroup = (item, isCode) => {
  const groups = isCode ? item?.map((x) => x?.code).toString() : item?.map((x) => x?.targetGroup).toString();
  const addSpace = groups?.replaceAll(`,`,`, `);
  if(addSpace?.length > 40)return `${addSpace.substring(0, 40)}...`;
  return addSpace;
};

export const getTargetGroupTooltip = (item) => {
  if(!item?.length)return
  return item?.map((x) => <p>{x?.targetGroup}</p>);
}

export const stateStatusList = [
  {id: 1, stateStatus: "Pending"}, 
  {id: 2, stateStatus: "Yes"},
  {id: 3, stateStatus: "No"}
];

export const convertNameStateStatus = (name) => {
  if (!name) return '';
  const value = name?.toLowerCase();
  if(value === 'yes') return 'Certified'
  if(value === 'no') return 'Denied'
  return name;
};

export const formatCurrency = (value) => {
  if(value === null || value === undefined)return;
  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export const isValidUrl = (url) => {
  try {
      new URL(url);
      return true;
  } catch (error) {
      return false;
  }
}

export const redirectToBulkhorn = (url) => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = () => {
    window.history.pushState(null, "", window.location.href);
  };
  // Redirige a otro dominio sin guardar en el historial
  window.location.replace(url);
  return null;
}

export const showTextDriverLicense = (lang) => {
  const textes = 'Para garantizar que se procese su cuestionario de evaluación de WOTC, debe cargar una imagen clara del frente de su licencia de conducir actual (no vencida). Este paso es esencial para verificar su información y completar el proceso de evaluación. Si no carga una licencia de conducir válida, no se procesará su cuestionario. Una vez que haya seleccionado y cargado la imagen, el sistema la almacenará automáticamente de forma segura y lo dirigirá sin problemas a la página de confirmación, indicando que su envío está completo. Asegúrese de que la imagen sea clara y que todos los detalles sean visibles para evitar demoras en el procesamiento.';
  const texten = 'To ensure your WOTC screening questionnaire is processed, you must upload a clear image of the front of your current (not expired) driver’s license. This step is essential for verifying your information and completing the screening process. Without a valid driver’s license upload, your questionnaire will not be processed. Once you have selected and uploaded the image, the system will automatically store it securely and seamlessly direct you to the confirmation page, indicating that your submission is complete. Please make sure the image is clear and all details are visible to avoid any delays in processing.';
  if(lang === 'es')return textes;
  return texten;
}
