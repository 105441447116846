// @vendors
import React, { useContext, useMemo } from 'react'
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';

// @assets
import { EmployeeTableStyles } from '../../../assets/css/employeeTable-style';
import { GeneralStyles } from '../../../assets/css/general-style';

// @components
import ToolbarTicket from './ToolbarTicket';
import { TicketContext } from '../TicketManagement';
import { TablePaginationActions } from '../../Admin/components/custom/TablePaginationActions';
import TicketRow from './TicketRow';

const TicketTable = () => {
    const styles = useMemo(() => EmployeeTableStyles(), []);
    const generalStyle = useMemo(() => GeneralStyles(), []);
    const {rowsState, setRowsState, handleMapResponse} = useContext(TicketContext);

    const handlePageChange = (event, nextPage) => {
        const page = nextPage === 0 ? 1 : nextPage === rowsState.currentPage ? nextPage + 1 : nextPage
        setRowsState((prev) => ({
          ...prev,
          currentPage: parseInt(page),
          pageSize:rowsState.pageSize
        }));
        handleMapResponse({page: page, size: parseInt(rowsState.pageSize)});
    };
    
    const handlePageSizeChange = (event) => {
        setRowsState((prev) => ({
          ...prev,
          currentPage: 1,
          pageSize: event.target.value
        }));
        handleMapResponse({size:event.target.value});
    };

  return (
    <Paper elevation={4}>
        <ToolbarTicket />
        <TableContainer sx={styles.tableContainer}>
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow sx={styles.tableRow}>
                <TableCell sx={styles.emptyTableCell} />

                <TableCell sx={{ ...styles.tableHeadTitle, textAlign: 'left', paddingLeft: '10px' }}>
                  Name
                </TableCell>

                <TableCell sx={{ ...styles.tableHeadTitle, ...generalStyle.tableColumnHide, textAlign: 'left' }}>
                  Creation date
                </TableCell>
                
                <TableCell sx={{ ...styles.tableHeadTitle, ...generalStyle.tableColumnHide, textAlign: 'left' }}>
                  Priority
                </TableCell>

                <TableCell sx={{ ...styles.tableHeadTitle, textAlign: 'left' }}>
                  Resolved
                </TableCell>
                    
                <TableCell sx={styles.emptyTableCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsState?.rows?.length
                ? React.Children.toArray(rowsState?.rows?.map((row) => <TicketRow row={row} />))
                : null
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 20, 50, 100]}
          colSpan={3}
          count={rowsState.rowCount || 0}
          rowsPerPage={parseInt(rowsState.pageSize)}
          page={rowsState.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={(e) => handlePageSizeChange(e)}
          ActionsComponent={() => TablePaginationActions(rowsState.rowCount, rowsState.currentPage, rowsState.pageSize, handlePageChange)}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
        />
    </Paper>
  )
}

export default TicketTable