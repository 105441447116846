// vendors
import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

// @state
import { useUI } from "../app/context/ui";
import { types } from "../redux/types";

// @assets
import { HeaderStyles } from "../assets/css/general-style";
import logo from "../assets/images/logoDashboardPublic.svg";

// @helpers
import AppHelper from "../helpers/AppHelper";
import DialogUI from "./shared/DialogUI";
import { LISTROLES } from "../helpers/ListRoles";
import { permissionsForOrganization } from "../helpers/FunctionsUtils";

// @material-ui
import {
  AccountCircleRoundedIcon,
  AppBar,
  Box,
  CssBaseline,
  ExpandMoreIcon,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "./shared/MaterialUI";

// @constants
import { ROUTENAME } from "../navigation/RouteName";

// @services
import OrganizationServiceNewApi from "../services/newApi/OrganizationService";

const Header = ({companyLogo}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const globalState = useSelector((loadedState) => loadedState);
  const id = globalState?.user?.companies?.length ? globalState?.user?.companies[0]?.id : '';
  const { snackbarUI } = useUI();
  const dialogUI = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const styles = useMemo(() => HeaderStyles(), []);
  const location = window.location.href;
  const currentUrl = location.toString();
  const organizationService = useMemo(() => new OrganizationServiceNewApi(), []);
  
  const logout = async () => {
    try {
      dialogUI.current.close();
      dispatch({ type: "LOGOUT" });
      history.push(ROUTENAME.login);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const redirectToForm = () => {
    const pathname = window.location.pathname;
    const companyId = pathname.substring(pathname.lastIndexOf("/") + 1);
    const verifiedId = esUUIDValido(companyId) ? companyId : id
    history.push(`${ROUTENAME.forms}${verifiedId}`);
  }

  const esUUIDValido = (uuid) => {    
    const regexUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;    
    return regexUUID.test(uuid);
  }

  const saveLastOrganization = async (organization) => {
    try {
      await organizationService.updateLastOrganization(organization?.id);
      //if(currentUrl?.includes(ROUTENAME.employer))history.push(ROUTENAME.dashboard);
    } catch (error) {
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const handleChangeOrganization = (e) => {
    const value = e.target.value;
    const itemSelected = globalState?.organizationReducer?.data?.find((item) => item?.id === value);
    saveLastOrganization(itemSelected);
    dispatch({
      type: types.ORGANIZATIONS_LIST,
      data: globalState?.organizationReducer?.data,
      organizationSelected: itemSelected
    });
    if(currentUrl?.includes(ROUTENAME.employer))history.push(ROUTENAME.dashboard);
  }
  
  /* const redirectUrl = (url) => {
    const isValid = isValidUrl(url);
    if(!isValid)return;
    const getPathnameUrl = new URL(url)
    const newUrl = `${getPathnameUrl.origin}${window.location.pathname}`
    return window.open(newUrl, "_self");
  } */

  const permissionForOrganizationPage = () => {
    if(globalState?.user?.role === LISTROLES.superAdmin ||
      permissionsForOrganization(globalState?.user?.role)
    )return true;
    return false;
  }

  const styleForLogo = () => {
    if(companyLogo)return styles.companyLogo
    if(globalState?.organizationReducer?.organizationSelected?.logo)return styles.organizationLogo
    return styles.logo
  }

  const imageSrc = () => {
    if(companyLogo)return companyLogo;
    if(globalState?.organizationReducer?.organizationSelected?.logo)return globalState?.organizationReducer?.organizationSelected?.logo;
    return logo;
  }

  const hasPermissionForUsers = () => {
    if(globalState?.user?.role === LISTROLES.superAdmin ||
      globalState?.user.role === LISTROLES.orgManager
    )return true;
    return false;
  }

  const handleOcr = async () => {
    try {
      handleMenuClose();
      await organizationService.ocrTrigger();
    } catch (error) {
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const renderMenu = (
    <Menu
      sx={{ mt: "50px" }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{vertical: "top", horizontal: "right"}}
      keepMounted
      transformOrigin={{vertical: "top", horizontal: "right"}}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          const settings = {
            confirm: true,
            btn: {
              confirm: "Log out",
              close: "Cancel",
            },
            onConfirm: () => logout(),
            type: "logout",
            styles: { ...styles.dialog },
          };
          setAnchorEl(false);
          dialogUI.current.open(
            "Are you sure?",
            "You are about to log out.",
            settings
          );
        }}
      >
        <Typography textAlign="center" sx={{ fontSize: "16px" }}>
          Logout
        </Typography>
      </MenuItem>

      <MenuItem onClick={() => redirectToForm()}>
        Forms
      </MenuItem>
      
      {globalState?.user?.role === LISTROLES.superAdmin || globalState?.user?.hasApiAccess
        ? (
          <MenuItem onClick={() => {history.push(ROUTENAME.apiClients)}}>
            Api Clients
          </MenuItem>
        ) : null
      }

      {/* {globalState?.user?.role === LISTROLES.superAdmin || globalState?.user?.isPayroll
        ? (
          <MenuItem onClick={() => {history.push(ROUTENAME.payroll)}}>
            Payroll
          </MenuItem>
        ) : null
      } */}

      {permissionForOrganizationPage() &&
        <MenuItem onClick={() => {history.push(ROUTENAME.organizations)}}>
          Organizations
        </MenuItem>
      }
      
      {hasPermissionForUsers()
        ? <MenuItem onClick={() => {history.push(ROUTENAME.userManagement)}}>
            User Management
          </MenuItem>
        : null
      }

      <MenuItem onClick={() => {history.push(ROUTENAME.manuals)}}>
        Manuals
      </MenuItem>
      
      {globalState?.user?.role === LISTROLES.superAdmin
        ? <MenuItem onClick={handleOcr}>
            OCR Trigger
          </MenuItem>
        : null
      }

      {
        globalState?.user?.role === LISTROLES.superAdmin || globalState?.user?.hasTmsAccess
          ? (<MenuItem onClick={() => {history.push(ROUTENAME.ticket)}}>
              System tickets
            </MenuItem>)
          : null
      }
    </Menu>
  );

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" sx={styles.appBar}>
        <Toolbar sx={styles.toolbar}>
          <Grid sx={styles.containerImg}>
            <Box sx={styleForLogo()}>
              <Link to={{pathname: ROUTENAME.dashboard}}>
                <img
                  src={imageSrc()}
                  alt="Company logo"
                  className="imageHeader"
                />
              </Link>
            </Box>

            {globalState?.organizationReducer?.data?.length > 1
              ? <FormControl fullWidth>
                  <Select
                    id="demo-simple-select"
                    value={globalState?.organizationReducer?.organizationSelected?.id}
                    defaultValue={globalState?.organizationReducer?.organizationSelected?.id}
                    size="small"
                    sx={{minWidth: "100px", height:"40px"}}
                    onChange={(e) => handleChangeOrganization(e)}
                  >
                    {globalState?.organizationReducer?.data?.map((item) => (
                      <MenuItem
                        key={item?.id}
                        value={item?.id}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              : null
            }
          </Grid>

          <Box className={styles.wrapperLogout}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
              style={{ margin: "0" }}
            >
              <AccountCircleRoundedIcon sx={{ fontSize: { xs: 32, sm: 56 } }} />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              style={{ margin: "0" }}
            >
              <ExpandMoreIcon sx={{ fontSize: "24px" }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <DialogUI ref={dialogUI} />
    </>
  );
};

export default Header;