// @vendors
import axios from 'axios';
import {
  get as _get, has as _has, isNull as _isNull,
} from 'lodash';
import store from '../../redux/store';

// @constants
import { ROUTENAME } from '../../navigation/RouteName';

class AppServiceClient {

  constructor() {
    this.http = axios.create({
      baseURL: process.env.REACT_APP_API_MAIN_URL_NEW_API,
    });
    this.http.defaults.params = {};
    this.initInterceptors();
  }

  initInterceptors() {
    this.http.interceptors.request.use(
      (request) => {
        const accessToken = this.getAccessToken();
        if (!_isNull(accessToken)) {
          request.headers.common['Authorization'] = 'Bearer ' + accessToken;
        }
        return request;
      },
      (error) => Promise.reject(error)
    );

    this.http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response.status === 401 &&
          _has(error.response, 'config.url') &&
          _get(error.response, 'config.url') !== undefined &&
          _get(error.response, 'config.url') !== '/auth/login'
        ) {
          //window.location = '/login';
          window.location = ROUTENAME.logout;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getAccessToken() {
    const state = store.getState();
    return state?.user?.client?.access_token ?? null;
  }
  
}

export default AppServiceClient;
