// @vendors
import React, { useEffect, useMemo, useState } from "react";
import sx from "mui-sx";
import {
  Badge,
  Checkbox,
  FormControlLabel,
  tooltipClasses
} from "@mui/material";
import { isNull as _isNull } from "lodash";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @assets
import { EmployeeTableStyles } from "../../../../../assets/css/employeeTable-style.js";
import { theme } from "../../../../../assets/theme";

// @material
import {
  Box,
  Collapse,
  TableCell,
  TableRow,
  Tooltip,
  ExpandMoreIcon,
  IconButton,
} from "../../../../../components/shared/MaterialUI.js";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';

// @components
import EmployeeDetail from "./EmployeeDetail.js";

// @helpers
import ConfirmDialog from "../../helpers/DialogConfirm.js";
import AppHelper from "../../../../../helpers/AppHelper.js";
import { convertDateToUTCTexas, permissionsForAddOrEditEmployees } from "../../../../../helpers/FunctionsUtils.js";

// @state
import { useUI } from "../../../../../app/context/ui.js";
import { types } from "../../../../../redux/types/index.js";

// @service
import EmployeeCompanyServiceNewApi from "../../../../../services/newApi/EmployeeCompanyServiceNewApi.js";
import { ROUTENAME } from "../../../../../navigation/RouteName.js";
import { LISTROLES } from "../../../../../helpers/ListRoles.js";

const EmployeeRow = (props) => {
  const { row } = props;
  const dispatch = useDispatch();
  const { blockUI, snackbarUI } = useUI();
  const styles = useMemo(() => EmployeeTableStyles(), []);
  const employeeServiceNewApi = useMemo(() => new EmployeeCompanyServiceNewApi(), []);
  const globalState = useSelector((state) => state);
  const stateDetail = globalState?.employeeSelected;
  const user = globalState?.user;
  const [open, setOpen] = useState(false);
  const [certification, setCertification] = useState('');
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [checkSelected, setCheckSelected] = useState(null);
  const [employeeInfo, setEmployeeInfo] = useState();
  const history = useHistory()

  const handleOpen = async () => {
    if(row?.isDeleted)return;
    if(stateDetail?.detail !== row?.id)return await getEmployeeById(); 
    saveRowId();
    setOpen(!open);
  };

  const getEmployeeById = async () => {
    try {
      blockUI.current.open(true);
      const response = await employeeServiceNewApi.getEmployeeById(row?.id, row?.companyId);
      setEmployeeInfo(response?.data?.data);
      blockUI.current.open(false);
      setOpen(!open);
      return saveRowId();
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const saveRowId = () => {
    dispatch({
      type: types.ID_EMPLOYEE_SELECTED,
      detail: !stateDetail?.detail || stateDetail?.detail !== row?.id ? row?.id : ''
    });
  }

  //add color if amount has value
  const bgColor = (wotc = true) => {
    if (wotc) {
      return _isNull(row?.amount) ? '#9ea19a' : row?.amount === 0 ? 'red' : 'green';
    } else {
      return _isNull(row?.submitterPreprocessStatus) ? '#9ea19a' : row?.submitterPreprocessStatus ? 'green' : 'red';
    }
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 350,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const nameGroupQualifie = (name, subGroups) => {
    if(name || subGroups){
      const groupVeteran = subGroups ? subGroups?.split(',') : []
      const spaceName = name ? name?.split(',') : [];
      const groupsAndSubgroups = spaceName?.concat(groupVeteran)
      return groupsAndSubgroups?.map((tile,index) => (
        <p key={index}>{tile}</p>
      ))
    }
    return name
  }

  const wotcBadge = () => {
    const bgColor = _isNull(row?.isElegible) ? '#9ea19a' : row?.isElegible ? 'green' : 'red';
    return (
      <Badge
        color="secondary"
        badgeContent={0}
        variant="dot"
      >
        <Box
          component="span"
          sx={{ bgcolor: bgColor, width: 30, height: 30 }}
        />
      </Badge>
    )
  }

  useEffect(() => {
    setCertification(row?.stateCertification)
    setCheckSelected(row?.stateCertification)
  }, [row])  

  const handleChangeCheck = (e) => {
    setCheckSelected('')
    const check = e?.target
    if(check.checked){
      setCheckSelected(check.value)
      if(!certification)return stateCertificationEmpty(check.value)
      setOpenModalConfirm(true)
    }else{
      setCheckSelected(null)
      setOpenModalConfirm(true)
    }
  }

  const stateCertificationEmpty = (value) => {
    const objectToUpdate = {
      stateCertification: value,
      liveEz: row?.liveEz,
      summerYouthEmpZone: row?.summerYouthEmpZone,
      liveRrc: row?.liveRrc
    }
    submitEmployeeByProp(objectToUpdate)
    setCertification(value)
  }

  // call after close confirmation modal on state cerfication
  const handleClose = (newValue) => {
    setOpenModalConfirm(false);
    if(newValue === 'yes') {
      const objectToUpdate = {
        stateCertification: checkSelected,
        liveEz: row?.liveEz,
        summerYouthEmpZone: row?.summerYouthEmpZone,
        liveRrc: row?.liveRrc
      }
      submitEmployeeByProp(objectToUpdate)
      setCertification(checkSelected)
    }
    if(newValue === 'no') return
  };

  const handleChangeCheckEz = (e, type) => {
    const objectToUpdate = {
      stateCertification: row?.stateCertification,
      liveEz: row?.liveEz,
      summerYouthEmpZone: row?.summerYouthEmpZone,
      liveRrc: row?.liveRrc
    }
    if(type === 'ez') return submitEmployeeByProp({...objectToUpdate, liveEz: e.target.checked})
    if(type === 'sy') return submitEmployeeByProp({...objectToUpdate, summerYouthEmpZone: e.target.checked})
    return submitEmployeeByProp({...objectToUpdate, liveRrc: e.target.checked})
  }

  const submitEmployeeByProp = async (prop) => {
    try {
      blockUI.current.open(true);
      await employeeServiceNewApi.editEmployeeByProp(row?.companyId, row?.id, prop);
      const params = {
        page: props.pages?.currentPage,
        size: props.pages?.pageSize,
        filters: props.search
      }
      props.getAllEmployesByCompany(params);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const convertModifiedDate = () => {
    return convertDateToUTCTexas(row?.modifiedLast)
  }

  const showTargetGroups = () => {
    if(!row?.qualifie && !row?.subQualifie)return ''
    const groupVeteran = row?.subQualifie ? row?.subQualifie?.split(',') : []
    const spaceName = row?.qualifie ? row?.qualifie?.split(',') : []
    const groupsAndSubgroups = spaceName?.concat(groupVeteran)
    const convertToString = groupsAndSubgroups?.toString();
    const replaceString = convertToString?.replace(/,/g, ', ')
    return replaceString
  }

  const showDashboardStatus = () => {
    if(!row?.dashboardStatus)return (<Box sx={styles.qStatusBox}>Sent</Box>)
    if(row?.status === 'filled_out')return (
      <Box sx={styles.qStatusBox}>
        {row?.dashboardStatus} <span style={{ color: 'red' }}>*</span>
      </Box>
    )
    return (<Box sx={styles.qStatusBox}>{row?.dashboardStatus}</Box>)
  }

  const getRamainingDay = () => {
    if(row?.remainDay < 0) return 0;
    if(row?.remainDay === '0') return 'Today';
    return row?.remainDay || "-";
  }

  const handleDelete = async () => {
    try {
      blockUI.current.open(true);
      row?.isDeleted ? await employeeServiceNewApi.activeEmployee(row?.companyId, row?.id) : await employeeServiceNewApi.deleteEmployee(row?.companyId, row?.id);
      successDelete();
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const successDelete = async () => {
    const params = {
      page: props.pages?.currentPage,
      size: props.pages?.pageSize,
      filters: {...props.search, IsDeleted: row?.isDeleted},
      stats: row?.isDeleted ? false : true,
    }
    props.getAllEmployesByCompany(params);
  }

  const permissionsInactiveEmployee = (role) => {
    if(role === LISTROLES.superAdmin || role === LISTROLES.orgManager )return true;
    return false;
  }

  const tooltipIconDelete = () => {
    if(row?.isDeleted) return 'Active employe';
    return 'You are about to delete this employee from the board, but you can reactivate it in the deleted employees section.'
  }
 
  return (
    <>
      <TableRow>
        <TableCell
          scope="row"
          sx={sx(
            styles.tableCell,
            { condition: open, sx: styles.borderNone },
            { condition: !open, sx: styles.bottomBorder }
          )}
        >
          <IconButton onClick={handleOpen}>
            <ExpandMoreIcon />
          </IconButton>
        </TableCell>
        {
          props.isSearch && <TableCell
            scope="row"
            sx={sx(
              styles.tableCellCompany,
              { condition: open, sx: styles.borderNone },
              { condition: !open, sx: styles.bottomBorder }
            )}
            style={{cursor: 'pointer', }}
            onClick={() => history.push(`${ROUTENAME.employer}${row?.companyId}`)}
          >
            {row?.company}
          </TableCell>
        }

        <TableCell
          scope="row"
          sx={sx(
            styles.tableCell,
            { condition: open, sx: styles.borderNone },
            { condition: !open, sx: styles.bottomBorder }
          )}
        >
          {row.fullName
}
        </TableCell>

        <TableCell
          scope="row"
          sx={sx(
            styles.tableCellLastModified,
            { condition: open, sx: styles.borderNone },
            { condition: !open, sx: styles.bottomBorder }
          )}
        >
          {convertModifiedDate()}
        </TableCell>

        <TableCell
          sx={sx(
            styles.tableCell,
            styles.tableColumnHide,
            { condition: open, sx: styles.borderNone },
            { condition: !open, sx: styles.bottomBorder }
          )}
        >
          {showDashboardStatus()}
        </TableCell>

        <TableCell
          sx={
            (sx(
              styles.tableCell,
              styles.tableColumnHide,
              { condition: open, sx: styles.borderNone },
              { condition: !open, sx: styles.bottomBorder }
            ),
              sx({
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.25em",
                color: row?.remainDay <= 5 ? "red" : "black",
              }, styles.tableColumnHide))
          }
        >
          {getRamainingDay()}
        </TableCell>

        <TableCell
          sx={
            (sx(
              styles.tableCell,
              styles.tableColumnHide,
              { condition: open, sx: styles.borderNone },
              { condition: !open, sx: styles.bottomBorder }
            ),
              sx({
                textAlign: "center",
                fontSize: "1.25em",
              }, styles.tableColumnHide))
          }
        >
          {showTargetGroups()}
        </TableCell>

        <TableCell sx={sx({ textAlign: 'center' }, styles.tableColumnHide)}>
          {
            (row?.qualifie || row?.subQualifie)
              ? ( <HtmlTooltip title={nameGroupQualifie(row?.qualifie, row?.subQualifie) ?? ''}>
                    {wotcBadge()}
                  </HtmlTooltip>)
              : (wotcBadge())
          }
        </TableCell>

        <TableCell sx={sx({ textAlign: 'center' }, styles.tableColumnHide)}>
          {row?.submitterPreprocessStatus || _isNull(row?.submitterPreprocessStatus) ? (
            <Badge color="secondary" badgeContent={0} variant="dot">
              <Box component="span" sx={{ bgcolor: bgColor(false), width: 30, height: 30 }} /> 
            </Badge>
          ) : (
            <HtmlTooltip
              title={
                <React.Fragment>
                  {row?.preprocessLogs?.map((rw, i) => {
                    return (
                      <p key={i}>{rw.message}</p>
                    );
                  })}
                </React.Fragment>
              }
            >
              <Badge color="secondary" badgeContent={0} variant="dot">
                <Box component="span" sx={{ bgcolor: bgColor(false), width: 30, height: 30 }} />
              </Badge>
            </HtmlTooltip>
          )}
        </TableCell>

        <TableCell sx={sx({ textAlign: 'center' }, styles.tableColumnHide)}>
          <div style={{display: 'flex', gap: 0}}>
            <FormControlLabel
              checked={row?.stateCertification === 'Yes'}
              sx={{
                margin: 0,
                pointerEvents: row?.isDeleted ? 'none' : permissionsForAddOrEditEmployees(user?.role) ? 'auto' : 'none',
              }}
              value='Yes'
              control={
                <Checkbox
                  sx={{'& .MuiSvgIcon-root': { fontSize: 36 }}}
                  color="success"
                  onChange={(e) => handleChangeCheck(e)}
                />
              }
            />
            <FormControlLabel
              checked={row?.stateCertification === 'No'}
              sx={{
                margin: 0,
                pointerEvents: row?.isDeleted ? 'none' : permissionsForAddOrEditEmployees(user?.role) ? 'auto' : 'none',
              }}
              value='No'
              control={
                <Checkbox
                  sx={{'& .MuiSvgIcon-root': { fontSize: 36 }}}
                  color="success"
                  onChange={(e) => handleChangeCheck(e)}
                />
              }
            />
            <FormControlLabel
              checked={row?.stateCertification === 'Pending'}
              sx={{
                margin: 0,
                pointerEvents: row?.isDeleted ? 'none' : permissionsForAddOrEditEmployees(user?.role) ? 'auto' : 'none',
              }}
              value='Pending'
              control={
                <Checkbox
                  sx={{'& .MuiSvgIcon-root': { fontSize: 36 }}}
                  color="success"
                  onChange={(e) => handleChangeCheck(e)}
                />
              }
            />
            <ConfirmDialog
              id="confirm-dialog"
              keepMounted
              open={openModalConfirm}
              onClose={handleClose}
            />
          </div>
        </TableCell>

        <TableCell sx={sx({ textAlign: 'center'}, styles.tableColumnHide)}>
          <div style={{display: 'flex', gap: 0, alignItems: 'center', justifyContent: 'center'}}>
            <FormControlLabel
              checked={row?.liveEz}
              sx={{
                margin: 0,
                pointerEvents: row?.isDeleted ? 'none' : permissionsForAddOrEditEmployees(user?.role) ? 'auto' : 'none',
              }}
              control={
                <Checkbox
                  sx={{'& .MuiSvgIcon-root': { fontSize: 36 }}}
                  color="success"
                  onChange={(e) => handleChangeCheckEz(e, 'ez')}
                />
              }
            />
          </div>
        </TableCell>
        
        <TableCell sx={sx({ textAlign: 'center'}, styles.tableColumnHide)}>
          <div
            style={{
              display: 'flex',
              gap: 0,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FormControlLabel
              checked={row?.liveRrc}
              sx={{
                margin: 0,
                pointerEvents: row?.isDeleted ? 'none' : permissionsForAddOrEditEmployees(user?.role) ? 'auto' : 'none',
              }}
              control={
                <Checkbox
                  sx={{'& .MuiSvgIcon-root': { fontSize: 36 }}}
                  color="success"
                  onChange={(e) => handleChangeCheckEz(e, 'rrc')}
                />
              }
            />
          </div>
        </TableCell>
        
        <TableCell sx={sx({ textAlign: 'center'}, styles.tableColumnHide)}>
          <div style={{display: 'flex', gap: 0, alignItems: 'center', justifyContent: 'center'}}>
            <FormControlLabel
              checked={row?.summerYouthEmpZone}
              sx={{
                margin: 0,
                pointerEvents: row?.isDeleted ? 'none' : permissionsForAddOrEditEmployees(user?.role) ? 'auto' : 'none',
              }}
              control={
                <Checkbox
                  sx={{'& .MuiSvgIcon-root': { fontSize: 36 }}}
                  color="success"
                  onChange={(e) => handleChangeCheckEz(e, 'sy')}
                />
              }
            />
          </div>
        </TableCell>
        
        <TableCell sx={sx({ textAlign: 'center'}, styles.tableColumnHide)}>
          <div
            style={{
              display: 'flex',
              gap: 0,
              alignItems: 'center',
              justifyContent: 'center',
              pointerEvents: 'none'
            }}
          >
            <FormControlLabel
              checked={row?.stateSent}
              sx={{
                margin: 0,
                pointerEvents: row?.isDeleted ? 'none' : permissionsForAddOrEditEmployees(user?.role) ? 'auto' : 'none',
              }}
              control={
                <Checkbox
                  sx={{'& .MuiSvgIcon-root': { fontSize: 36 }}}
                  color="success"
                />
              }
            />
          </div>
        </TableCell>

        {permissionsInactiveEmployee(user?.role)
          ? <TableCell
              scope="row"
              sx={sx(
                styles.tableCell,
                styles.tableColumnHide
              )}
            >
              <HtmlTooltip title={tooltipIconDelete()}>
                <IconButton onClick={handleDelete}>
                  {row?.isDeleted ? <ToggleOffOutlinedIcon/> : <DeleteOutlineOutlinedIcon />}
                </IconButton>
              </HtmlTooltip>
            </TableCell>
          : null
        }
      </TableRow>

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, width: '100%'}}
          sx={sx(
            styles.tableCell,
            { padding: "0"},
            { condition: !open, sx: styles.borderNone },
            { condition: open, sx: styles.bottomBorder }
          )}
          colSpan={12}
        >
          <Collapse
            in={stateDetail?.detail === row?.id ? true : false}
            //in={open}
            timeout="auto"
            unmountOnExit
            sx={{ padding: "0 100px", [theme.breakpoints.down('md')]: { padding: '0 10px' } }}
          >
            <EmployeeDetail
              employeeInfo={employeeInfo}
              pages={props.pages}
              getAllEmployesByCompany={props.getAllEmployesByCompany}
              search={props.search}
              companyId={row?.companyId}
              setOpen={setOpen}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default EmployeeRow;
