import { addUser } from "../redux/actions/user"
import store from "../redux/store"
import ClientServiceNewApi from "../services/AppServiceClient/ClientService"
import AuthServiceNewApi from "../services/newApi/AuthServiceNewApi"
import AppHelper from "./AppHelper"

const clientServiceNewApi = new ClientServiceNewApi()
const authServiceNewApi = new AuthServiceNewApi();
const CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_API_CLIENT_SECRET

export const authForSuperAdmin = async (dispatch, snackbarUI, islogin, clientSecret, clientId) => {
  const params = new URLSearchParams()
  params.append('grant_type', 'client_credentials')
  params.append('client_id', clientId ? clientId : CLIENT_ID)
  params.append('client_secret', clientSecret ? clientSecret : CLIENT_SECRET)
  const token = await respAuthAdmin(params, dispatch, snackbarUI, islogin)
  return token
}

const respAuthAdmin = async (params, dispatch, snackbarUI, islogin) => {
  const state = store.getState()
  try {
    const response = await clientServiceNewApi.authClient(params)
    const data = response?.data
    if(islogin){
      let payload = { ...state?.user, client: data};
      dispatch(addUser(payload));
    }
    return data
  } catch (error) {
    AppHelper.checkError(error, snackbarUI);
  }
}

// get token for new api
export const newAuthToken = async (values, snackbarUI) => {
  const params = new URLSearchParams()
  params.append('grant_type', 'password')
  params.append('client_id', CLIENT_ID)
  params.append('client_secret', CLIENT_SECRET)
  params.append('username', values?.email)
  params.append('password', values?.password)
  const data = await respNewAuth(params, snackbarUI)
  return data
}

const respNewAuth = async (params, snackbarUI) => {
  try {
    const response = await authServiceNewApi.loginNewApi(params)
    const data = response?.data
    const currentDate = new Date().getTime()
    const timeTokenMilisecons = data?.expires_in * 1000
    const TokenLifetime = currentDate + timeTokenMilisecons
    const expiryTime = decreaseTokenLifetime(TokenLifetime)
    decreaseTokenLifetime(expiryTime)
    return {data:data, expiryTime:expiryTime}
  } catch (error) {
    if(error && error?.response?.status > 399 && error?.response?.status !== 404) AppHelper.checkError(error, snackbarUI);
    return {data:null, expiryTime:null}
  }
}

const decreaseTokenLifetime = (time) => {
  const minuteToMilliseconds = 60000
  const minutesToReduce = minuteToMilliseconds * 5
  return time - minutesToReduce
}
