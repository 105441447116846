// @vendors
import React, {useState, useEffect, useMemo} from 'react';
import { useSelector } from 'react-redux';

// @styles
import { StyleAPiClient } from './StyleApiClient.js';

// @components
import {
    Box,
    Container,
    Typography,
} from "../../components/shared/MaterialUI.js";
import TableApiClient from './TableApiClient.js';

// @helpers
import AppHelper from '../../helpers/AppHelper.js';

// @service
import ClientServiceNewApi from '../../services/AppServiceClient/ClientService.js';

// @state
import { useUI } from '../../app/context/ui.js';

const ApiClients = () => {
  const styles = useMemo(() => StyleAPiClient(), []);
  const clientService = useMemo(() => new ClientServiceNewApi(), []);
  const [data, setData] = useState([]);
  const { blockUI, snackbarUI } = useUI();
  const state = useSelector((loadedState) => loadedState);
  const organizationId = state?.organizationReducer?.organizationSelected?.id;

  const getAllClients = async () => {
    try {
      blockUI.current.open(true);
      const response = await clientService.getClients(organizationId);
      const newList = response?.data?.sort((a, b) => {
        if(a?.name?.toLowerCase() < b?.name?.toLowerCase())return -1;
        if(a?.name?.toLowerCase() > b?.name?.toLowerCase())return 1;
        return 0;
    })
      setData(newList)
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  useEffect(() => {
    getAllClients();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  return (
    <Container
      component="section"
      maxWidth="xl"
      sx={styles.containerApiClient}
    >
      <Box sx={styles.header}>
        <Typography sx={{ fontSize: { xs: "20px", md: "28px" }, fontWeight: "bold" }}>Api Clients</Typography>
      </Box>

      <TableApiClient data={data} getAllClients={getAllClients}/>
    </Container>
  )
}

export default ApiClients