import { theme } from "../../assets/theme";

export const StyleAPiClient = (props) => ({
    containerApiClient: {
        minHeight: '100vh',
        color: '#101010',
        padding: '0 48px',
        [theme.breakpoints.down('md')]: {
          padding: '0px',
        },
    },
    toolbar:{
      margin: '30px 0 0 0',
      padding: '20px 0',
      borderBottom: "1px solid rgba(224,224,224,1)",
    },
    header:{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    btnAdd:{
        display: 'inline-block',
        padding:'10px 15px',
        fontSize: '1.2rem',
        textTransform: 'none',
        backgroundColor: '#101010',
        color: '#FFF',
        '&:hover': {
          backgroundColor: '#101010',
        }
    },
    btnReturn:{
      display: 'inline-block',
      padding:'10px 15px',
      fontSize: "1.2rem",
      textTransform: "none",
      background: "#FFF",
      color: "#101010",
      border: "1px solid #101010",
      boxShadow: "0",
      "&:hover": {
        background: "#FFF",
        boxShadow: 3,
      },
    },
    tableContainer: {
      margin: '0px',
      minHeight: "400px",
      maxHeight: "500px",
    },
    tableBody: {
      "::-webkit-scrollbar": {
        width: "10px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "rgba(16, 16, 16, 0.5)",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
    tableHeadTitle: {
      textAlign: "start !important",
      fontSize: "1.5rem",
      fontWeight: 700,
      padding: '20px 0 !important'
    },
    emptyTableCell: {
      width: "4%",
    },
    tableColumnHide: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    tableCell: {
      fontSize: "1.5rem",
      flex: 1,
      borderBottom: "1px solid rgba(224,224,224,1)",
    },
    tableCellNoBorder: {
      fontSize: "1.5rem",
      flex: 1,
      border: "none",
    },
    bodyCollapse:{
      margin: 1,
      display: 'flex',
      justifyContent: 'space-between'
    },
    titleCollapse:{
      fontSize: "1.125rem",
      fontWeight: 700
    },
    subtitleCollapse:{
      fontSize: "1rem",
      fontWeight: 200,
      opacity: 0.8
    },
    containerBtnToken:{
      margin: '20px 0'
    },
    bodyCollapseMiddle:{
      width: '50%',
      display: 'flex',
      justifyContent: 'start',
      flexDirection: 'column !important',
    },
    divider:{
      width: '100%',
      color: '#0202'
    }
})

export const StyleAPiClientDialog = () => ({
  dialog:{
    display: 'flex',
    width: '100%',
    justifyContent: 'center !important',
  },
  title:{
    display:'flex',
    justifyContent: 'center',
    fontSize: '2rem',
    fontWeight: '700',
    color: '#101010',
    padding: '30px 0 0 0'
  },
  body:{
    width: '100%',
    miHeight: '200px',
    margin: '20px 0 0 0',
    padding: '0 30px 20px 30px'
  },
  formButtonWrapper: {
    padding: '15px 10px',
    width: '100%',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    [theme.breakpoints.down('md')]: {
     flexDirection: 'column-reverse',
     gap: '16px',
    },
  },
  btnSubmit:{
    height: '56px',
      width: '45%',
      backgroundColor: '#000',
      opacity: '1',
      color: '#FFF',
      '&:hover': {
        boxShadow: 12,
        backgroundColor: '#000',
        color: '#FFF',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '48px',
        fontSize: '1rem',
      },
  },
  btnCancel:{
      height: '56px',
      width: '45%',
      textTransform: 'none',
      backgroundColor: 'rgba(36, 35, 35, 0.3)',
      opacity: '0.5',
      color: '#FFF',
      fontWeight: '600',
      fontSize: '1.25rem',
      boxShadow: 4,
      '&:hover': {
        backgroundColor: 'rgba(36, 35, 35, 0.3)',
        boxShadow: 6,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '48px',
        fontSize: '1rem',
      },
  },
  boxItem:{
    width: '100%'
  },
  fieldsInput:{
    width:'100%'
  },
  fieldsForm:{
    width:'500px'
  },
  checkbox:{
    '&.MuiCheckbox-root': {
      color: theme.palette.primary.main,
    },
  }
})

export const StyleAPiClientModal = () => ({
  dialogToken:{
    display: 'flex',
    width: '100%',
    justifyContent: 'center !important',
    boxShadow: 24,
  },
  container:{
    display: 'block',
    width: '560px !important',
    boxSizing: 'border-box !important',
    padding: '30px'
  },
  DialogTitle:{
    width: '100% !important',
    boxSizing: 'border-box !important',
    margin:0,
    padding:0
  },
  divTitle:{
    display:'flex',
    justifyContent:'center !important',
    width: '100% !important',
    padding: '0 0 20px 0',
  },
  title:{
    fontSize: '1.1rem',
    fontWeight: '700',
    color: '#101010',
    margin:0,
    textAlign: 'center'
  },
  modal:{
    width: '500px !important',
    minHeight:'300px',
    bgcolor: '#fff',
    borderRadius: '5px',
    boxSizing: 'border-box !important',
    overflowX: 'hidden',
  },
  containerBody:{
    width: '500px !important',
    minHeight:'300px',
    boxSizing: 'border-box !important',
    overflowX: 'hidden',
  },
  token:{
    fontSize: '0.8rem',
    fontWeight: '200',
    color: '#101010',
    overflowWrap: 'break-word !important',
    wordWrap:'break-word',
    overflowX: 'hidden',
    fontFamily: 'inherit',
    margin:0
  },
  divButton:{
    display:'flex',
    justifyContent: 'end',
    width: '100%',
    padding: '20px 0 0 0px'
  },
  button:{
    height: '56px',
    backgroundColor: '#000',
    opacity: '1',
    color: '#FFF',
    padding: '5px 20px ',
    '&:hover': {
      boxShadow: 12,
      backgroundColor: '#000',
      color: '#FFF',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '48px',
      fontSize: '1rem',
    },
  },
})

