/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { Container, Grid } from '@mui/material';

// @assets
import { GeneralStyles } from '../../assets/css/general-style';

// @components
import Pageheader from '../../components/PageHeader';
import TicketTable from './components/TicketTable';

// helpers
import AppHelper from '../../helpers/AppHelper';

// context
import { useUI } from '../../app/context/ui';

// services
import TicketService from '../../services/newApi/TicketServiceNewApi';

const TicketManagement = () => {
  const generalStyle = useMemo(() => GeneralStyles(), []);
  const { blockUI, snackbarUI } = useUI();
  const ticketService = useMemo(() => new TicketService(), []);

  const [rowsState, setRowsState] = useState({
    pageSize: 10,
    rows: [],
    rowCount: 0,
    currentPage: 1,
    page: 0,
  });

  const handleMapResponse = async (params) => {
    blockUI.current?.open(true);
    const paginattion = {page: params?.page ?? 1, size: params?.size ?? rowsState.pageSize};
    try {
      const response = await ticketService.getTickets(paginattion);
      updateTableState(response?.data);
    } catch (error) {
      handleError(error);
    }
  }

  const updateTableState = useCallback((data) => {
    setRowsState((prev) => ({
      ...prev,
      pageSize: data?.pagination?.page_size || 10,
      rows: data?.data || [],
      rowCount: data?.pagination?.total_records || 0,
      currentPage: data?.pagination?.page_number,
      page: data?.pagination?.page_number - 1,
    }));
    blockUI.current?.open(false);
  }, []);

  const handleError = useCallback((error) => {
      AppHelper.checkError(error, snackbarUI);
      blockUI.current?.open(false);
    },
    [snackbarUI, blockUI]
  );

  useEffect(() => {
    handleMapResponse();
  }, [])

  return (
      <Container
        component="section"
        maxWidth="xl"
        sx={generalStyle.dashCtn}
      >
        <Container
          component="section"
          maxWidth="xl"
          sx={generalStyle.containerTitle}
        >
          <Pageheader
            title={'Ticket management'}
            subtitle={'Welcome to ticket page'}
          />
        </Container>
  
        <TicketContext.Provider value={{
            rowsState,
            setRowsState,
            handleMapResponse
          }}
        >
          <Grid>
            <TicketTable/>
          </Grid>
        </TicketContext.Provider>
      </Container>
    )
  }
    
export const TicketContext = createContext({});
export default TicketManagement