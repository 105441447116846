/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// @assets
import { EmployerStyles } from "../../assets/css/employer-style";

// @components
import {
  Box,
  Button,
  Grid,
  Typography,
} from "../../components/shared/MaterialUI";
import AdminView from "./components/AdminView";
import EmployeeTable from "./components/EmployeeTable/EmployeeTable";
import EmployerStats from "./components/EmployerStats";
import ChildTable from "./components/childs/ChildTable";

// @helperts
import { permissionsForEditEmployer } from "../../helpers/FunctionsUtils";

// @constants
import { types } from "../../redux/types";
import { ROUTENAME } from "../../navigation/RouteName";

// @hooks
import useEmployeesByCompany from "./hooks/UseEmployeesByCompany";

const Employer = (props) => {
  const state = useSelector((loadedState) => loadedState);
  const user = state.user;
  const sort = state.sortEmployeeReducer
  const id = props.id ? props.id : user?.companies[0]?.id;
  const styles = useMemo(() => EmployerStyles(), []);
  const history = useHistory();
  const dispatch = useDispatch()
  const pageEmployer = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    rowsState,
    setRowsState,
    getAllEmployesByCompany,
    getAllChildrens,
    childReachLimit,
    childrenWasLoaded,
    showChildrens,
    listChildrens,
    setShowChildrens,
    setListChildrens,
    employerDetails,
    setEmployerDetails,
    stats,
    textEmptyChild,
    setTextEmptyChild,
    setSearchChildren,
    searchChildren
  } = useEmployeesByCompany(sort, id);

  useEffect(() => {
    dispatch({
      type: types.ID_EMPLOYEE_SELECTED,
      detail: ''
    })
    dispatch({
      type: types.SORT_EMPLOYEE,
      sort: '',
      direction: ''
    });
    getAllChildrens(id);
  }, [])

  const adminProps = {
    id,
    name: employerDetails.name,
    displayName: employerDetails.displayName,
    status: employerDetails.status,
    setEmployerDetails,
    employerDetails
  };

  useEffect(() => {
    document.title = `RockerBox - ${employerDetails.name || ""}`;
  }, [employerDetails]);

  return (
    <>
      <Grid
        component="section"
        sx={styles.dashCtn}
      >
        {pageEmployer !== 0 && (
          <Button
            onClick={() =>
              history.push({
                pathname: ROUTENAME.dashboard,
                state: { page: pageEmployer },
              })
            }
            sx={styles.buttonLinkGoBack}
          >
            Return to dashboard
          </Button>
        )}

        <Box sx={styles.upperCtn}>
          {permissionsForEditEmployer(user?.role) ? (
            <AdminView {...adminProps} />
          ) : (
            <>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    margin: "0 0 20px",
                  }}
                >
                  {employerDetails.displayName ? employerDetails.displayName : employerDetails.name}
                </Typography>
                <Box sx={styles.statusBox}>{employerDetails.status}</Box>
              </Box>
              <Box sx={styles.buttonsBox}></Box>
            </>
          )}
        </Box>

        <EmployerStats id={id} stats={stats} />

        {childrenWasLoaded &&
          <EmployeeContext.Provider
            value={{
              rowsState,
              companyId: id || "",
              setRowsState,
              getAllEmployesByCompany,
              setShowChildrens,
              listChildrens,
              setListChildrens,
              getAllChildrens,
              childReachLimit,
              employerDetails,
              setTextEmptyChild,
              setSearchChildren,
              searchChildren
            }}
          >
            {(listChildrens?.rows?.length && showChildrens) || textEmptyChild
              ? <ChildTable />
              : <EmployeeTable />
            }
          </EmployeeContext.Provider>
        }

      </Grid>
    </>
  );
};

export const EmployeeContext = createContext({});
export default Employer;
