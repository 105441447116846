import AppServiceNewApi from "./AppServiceNewApi";

class TicketService extends AppServiceNewApi{
    constructor(){
        super();
        this.path = '/api/internal/v1/tickets';
    }

    getTickets(pagination){
        return this.http.get(`${this.path}?PageNumber=${pagination?.page}&PageSize=${pagination?.size}`);
    }
    
    getTicketDetail(id){
        return this.http.get(`${this.path}/${id}`);
    }

    updateStatus(id, status){
        return this.http.patch(`${this.path}/${id}?status=${status}`);
    }

    saveTicket(ticket){
        return this.http.post(`${this.path}`, ticket);
    }
    
    editTicket(id, ticket){
        return this.http.put(`${this.path}/${id}`, ticket);
    }

    saveImageByTicket(ticketId, file){
        return this.http.post(`${this.path}/${ticketId}`, file);
    }

    updateResponseDev(id, response){
        return this.http.patch(`${this.path}/dev-response/${id}`, response);
    }
}

export default TicketService;