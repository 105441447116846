/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @services
import EmployeeCompanyServiceNewApi from '../../../services/newApi/EmployeeCompanyServiceNewApi';
import CompanyServiceNewApi from '../../../services/newApi/CompanyServiceNewApi';
import StatsServiceNewApi from '../../../services/newApi/StatsServiceNewApi';

// @helpers
import AppHelper from '../../../helpers/AppHelper';

// @context
import { useUI } from '../../../app/context/ui';

// @constants
import { types } from '../../../redux/types';

const useEmployeesByCompany = (sort, companyId) => {
  const { blockUI, snackbarUI } = useUI();
  const employeeByCompanyService = useMemo(() => new EmployeeCompanyServiceNewApi(), []);
  const companyServiceNewApi = useMemo(() => new CompanyServiceNewApi(), []);
  const statsServices = useMemo(() => new StatsServiceNewApi(),[]);

  const dispatch = useDispatch();
  const { organizationSelected, data: organizationList } = useSelector((state) => state.organizationReducer || {});
  const organizationId = organizationSelected?.id;

  const [childReachLimit, setChildReachLimit] = useState(false);
  const [childrenWasLoaded, setChildrenWasLoaded] = useState(false);
  const [showChildrens, setShowChildrens] = useState(false);
  const [employerDetails, setEmployerDetails] = useState({});
  const [stats, setStats] = useState({});
  const [listChildrens, setListChildrens] = useState({
    rows: [],
    rowList: [],
    page: 0,
  });
  const [textEmptyChild, setTextEmptyChild] = useState(false);
  const [searchChildren, setSearchChildren] = useState('');

  const [rowsState, setRowsState] = useState({
    pageSize: 10,
    rows: [],
    rowCount: 0,
    currentPage: 0,
    page: 0,
    filters: { name: '', status: '' },
  });

  const handleError = useCallback((error) => {
      AppHelper.checkError(error, snackbarUI);
      blockUI.current?.open(false);
    },
    [snackbarUI, blockUI]
  );

  const updateTableState = useCallback((data) => {
    setRowsState((prev) => ({
      ...prev,
      pageSize: data?.pagination?.page_size || 10,
      rows: data?.data || [],
      rowCount: data?.pagination?.total_records || 0,
      currentPage: data?.pagination?.page_number,
      page: data?.pagination?.page_number - 1,
    }));
    blockUI.current?.open(false);
  }, []);

  const getAllEmployesByCompany = useCallback(async (params) => {
    const employer = params?.companyId ?? companyId;
    const paginattion = {page: params?.page ?? 1, size: params?.size ?? rowsState.pageSize};
    const filters = params?.filters || rowsState.filters;
    const sortBy = {order: params?.sort ?? sort.sort, direction: params?.direction ?? sort.direction};
    try {
        blockUI.current.open(true);
        const response = await employeeByCompanyService.getEmployeesByCompany(employer, organizationId, paginattion, filters, sortBy);
        const data = response?.data;
        updateTableState(data);
        if(params?.stats)callStats(employer);
    } catch (e) {
      handleError();
    }finally {blockUI.current.open(false);}
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeeByCompanyService, organizationId, rowsState, sort, companyId, updateTableState, handleError, blockUI]
  );

  const callStats = async (company) => {
    try {
      const r1 = await statsServices.getStats('', company);
      setStats({ ...r1?.data?.data });
    } catch (error) {
      handleError(error);
    }
  }

  const callEmployeesList = useCallback((company) => {
      setShowChildrens(false);
      const params = { companyId: company, filters: {} };
      getAllEmployesByCompany(params);
      setListChildrens((prev) => ({
        ...prev,
        rows: [],
        rowList: [],
        page: 0,
      }));
    },
    [getAllEmployesByCompany]
  );

  const checkIfHasChilds = useCallback((data, company) => {
      setChildrenWasLoaded(true);
      if (!data?.length) return callEmployeesList(company);
      setShowChildrens(true);
      setListChildrens((prev) => ({
        ...prev,
        rows: data,
        rowList: data,
        page: 0,
      }));
      blockUI.current.open(false);
    },
    [callEmployeesList, blockUI]
  );

  const preloadOrganization = useCallback((organizationIdToPreload) => {
    const org = organizationList?.find((org) => org?.id === organizationIdToPreload);
    if (org && org?.id !== organizationId) {
      dispatch({
        type: types.ORGANIZATIONS_LIST,
        data: organizationList,
        organizationSelected: org,
      });
    }
  },[organizationId, organizationList, dispatch]);

  // get data about the employee
  const getEmployerDetails = useCallback((response) => {
    const resp = response?.data?.data;
    if(resp?.organizationId)preloadOrganization(resp.organizationId);
    setEmployerDetails({
      name: resp?.name || '',
      status: resp?.status || '',
      data: resp,
      displayName: resp?.displayName || '',
    });
  },[companyServiceNewApi, preloadOrganization, snackbarUI]);

  //request all childrens for a company
  const getAllChildrens = useCallback(async (company) => {
      try {
        blockUI.current.open(true);
        const [r1, r2, r3] = await Promise.all([
          companyServiceNewApi.getChildsByCompany(company),
          companyServiceNewApi.getCompanyById(company),
          statsServices.getStats('', company)
        ]);
        const isLimit = r1?.data?.child_reach_limit ?? false;
        getEmployerDetails(r2);
        setStats({ ...r3?.data?.data });
        setChildReachLimit(isLimit);
        checkIfHasChilds(r1?.data?.data, company);
      } catch (error) {
        handleError(error);
      }
  },[companyServiceNewApi, checkIfHasChilds, handleError, blockUI, getEmployerDetails]);

  return {
    rowsState,
    setRowsState,
    getAllEmployesByCompany,
    getAllChildrens,
    childReachLimit,
    childrenWasLoaded,
    showChildrens,
    listChildrens,
    setShowChildrens,
    setListChildrens,
    employerDetails,
    setEmployerDetails,
    stats,
    setTextEmptyChild,
    textEmptyChild,
    setSearchChildren,
    searchChildren
  };
};

export default useEmployeesByCompany;
