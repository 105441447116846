// @vendors
import React, { useContext } from "react";
import { eq as _eq, mapKeys as _mapKeys } from "lodash";

// @ state
import { useUI } from "../../../app/context/ui.js";
import { QuestionnaireContext } from "../Prescreening";

// @assets
import { MultiQuestStyles } from "../../../assets/css/questionnaire-style.js";

// @material-ui
import {
  ArrowBackIcon,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from "../../../components/shared/MaterialUI.js";

// @helpers
import AppHelper from "../../../helpers/AppHelper.js";

// @components
import {
  DateQuestion,
  NumericalQuestion,
  SingleList,
  SingleTextQuestion,
  YesNoQuestion,
  YesNoUnsureQuestion,
  StateFederalQuestion,
} from "./components/Questions.js";
import DigitalSignature from "./DigitalSignature.js";
import MultiQuestion from "./MultiQuestion.js";

// @services
import QuestionnaireServiceNewApi from "../../../services/newApi/QuestionnaireServiceNewApi.js";

const SingleQuestion = () => {
  const {
    hashToken,
    employerId,
    lang,
    t,
    initial,
    updateQuestion,
  } = useContext(QuestionnaireContext);
  const styles = MultiQuestStyles();

  let questionLang = lang === "es" ? initial.spanishTitle : initial.title;
  const { blockUI, snackbarUI } = useUI();
  const questionaireService = new QuestionnaireServiceNewApi()
  
  if(initial?.code === 'Q69'){
    questionLang = initial?.title?.replaceAll(`*`,`\n *`);
  }

  const onSubmit = async (values) => {
    try {
      let responses 
      _mapKeys(values, function (value) {
        responses = payload(value);
      });
      blockUI.current.open(true);
      const res = employerId
        ? await questionaireService.questNext(responses, hashToken, lang, true)
        : await questionaireService.questNext(responses, hashToken, lang);
      updateQuestion(res?.data?.data);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const payload = (answer) => {
    return {
      questionId: initial?.id,
      type: initial?.type,
      answer: initial?.code === 'Q1' ? [answer] : answer,
      groupId: initial?.groupId,
    };
  };

  const getPrev = async () => {
    try {
      blockUI.current.open(true);
      const resp = employerId
        ? await questionaireService.questPrev(hashToken, initial?.id, initial?.groupId, true)
        : await questionaireService.questPrev(hashToken, initial?.id, initial?.groupId)
      updateQuestion(resp?.data?.data);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const props = {onSubmit};

  return (
    <Paper elevation={4} sx={styles.paper}>
      <Box sx={styles.topBar}>
        <Typography>
          {_eq(initial.type, "digital_signature")
            ? t("titles.digitalSignature")
            : initial?.code === 'Q2'
            ? t("titles.demographicInformation")
            : t("titles.wotcQuestionnaire")}
        </Typography>
        <Box sx={styles.progress}>
          {initial && initial?.id > 2 && (
            <IconButton onClick={() => getPrev()}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <LinearProgress variant="determinate" value={initial?.progress} />
        </Box>
      </Box>

      <Box sx={styles.container}>
        {_eq(initial?.type, "text") && (
          <>
            <Box sx={{ textAlign: "center", margin: "24px 0" }}>
              <Typography variant="h4">
                {t("question.nextQuestion")} {questionLang}
              </Typography>
            </Box>
            <Box sx={styles.buttonWrapper}>
              <Button
                color="inherit"
                onClick={() => {
                  onSubmit(initial?.type);
                }}
              >
                {t("question.btnNext")}
              </Button>
            </Box>
          </>
        )}
        
        {_eq(initial?.type, "text_only") && (
          <>
            <Box sx={{ textAlign: "center", margin: "24px 0" }}>
              <Typography variant="h4">
                {questionLang}
              </Typography>
            </Box>
            <Box sx={styles.buttonWrapper}>
              <Button
                color="inherit"
                onClick={() => {
                  onSubmit(initial?.type);
                }}
              >
                {t("question.btnNext")}
              </Button>
            </Box>
          </>
        )}
        {_eq(initial?.type, "multi_question") && <MultiQuestion />}
        {_eq(initial?.type, "basic_date") && (
          <DateQuestion {...props} key={initial?.id} />
        )}
        {_eq(initial?.type, "single_text") && <SingleTextQuestion {...props} />}
        {_eq(initial?.type, "numerical") && <NumericalQuestion {...props} />}
        {_eq(initial?.type, "yes_no") && <YesNoQuestion {...props} />}
        {_eq(initial?.type, "state_federal") && (
          <StateFederalQuestion {...props} />
        )}
        {_eq(initial?.type, "yes_no_unsure") && (
          <YesNoUnsureQuestion {...props} />
        )}
        {_eq(initial?.type, "single_list") && <SingleList {...props} />}
        {_eq(initial?.type, "digital_signature") && (
          <DigitalSignature {...props} />
        )}
      </Box>
    </Paper>
  );
};

export default SingleQuestion;