// @vendors
import React, {useContext, useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import {
  camelCase as _camelCase,
  findIndex as _findIndex,
  eq as _eq,
  map as _map,
} from 'lodash';
import * as Yup from 'yup';

// @store
import {useUI} from '../../../app/context/ui.js';

// @material-ui
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
} from '../../../components/shared/MaterialUI';

// @helpers
import AppHelper from '../../../helpers/AppHelper.js';
import { formatPhoneNumber, formatZipCode } from '../../../helpers/FormatPhoneNumber.js';
import { formatSSN, getRawSSN } from '../../../helpers/FormatSSN.js';

// @components
import {QuestionnaireContext} from '../Prescreening';
import {
  AppDatePicker,
  AppTextField,
  AppCheckbox,
  AppSelect,
} from "../../../components/forms";

// @assets
import {
  CheckboxStyles,
  MultiQuestStyles,
  SingleListStyles,
} from "../../../assets/css/questionnaire-style";

// @services
import HelpersServiceNewApi from "../../../services/newApi/HelpersServiceNewApi.js";
import QuestionnaireServiceNewApi from '../../../services/newApi/QuestionnaireServiceNewApi.js';

const findAnswer = (question, answer) => {
  if (!Array.isArray(answer)) return '';
  const hash = answer.reduce((result, item) => ({...result, [item.id]: item.answer}), {});
  return hash[question.id] ? hash[question.id] : '';
}

const MultiQuestion = () => {
  const {
    hashToken,
    employerId,
    lang,
    t,
    initial,
    updateQuestion,
  } = useContext(QuestionnaireContext);
  const styles = MultiQuestStyles();
  const stylesChk = CheckboxStyles();
  const stylesState = SingleListStyles();
  const {blockUI, snackbarUI} = useUI();
  const [isOtherCounty, setIsOtherCounty] = useState(false);
  const statesService = new HelpersServiceNewApi();
  const newQuestionService = new QuestionnaireServiceNewApi();

  const getCounties = async (state, county) => {
    try {
      blockUI.current.open(true);
      const response = await statesService.getLocalities(state, county)
      blockUI.current.open(false);
      const data = response?.data?.data
      return data
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  let query = initial?.questions ?? [];
  let inputValues = {};
  
  query.forEach((item) => {
    if(item?.type === 'other_county')return
    inputValues[_camelCase(item.title)] = findAnswer(item, query ?? initial?.answer)
    if(item?.type === 'add_other_county')inputValues = {...inputValues, otherCounty:inputValues?.county}
    if(item?.type === 'add_other_city')inputValues = {...inputValues, otherCity:inputValues?.city}
  });

  const handleChangeClick = (e, name, values, setFieldValue) => {
    const value = e.target.dataset.value;
    if(!value)return
    if (name === 'state') {
      const key = _findIndex(query, function (o) {return o.code === 'Q13'});
      if (key >= 0) {
        setCounties(value, key, setFieldValue)
      }
    } else if (name === 'county') {
      const state = values.state;
      const key = _findIndex(query, function (o) {
        return o.code === 'Q14';
      });
      if (key >= 0) {
        setCities(value, key, setFieldValue, state)
      }
    }
  };

  const setCounties = (value, key, setFieldValue) => {
    setFieldValue('county', '');
    setFieldValue('cityOrTown', '');
    setFieldValue('city', '');
    getCounties(value).then(r => {
      query[key]['options'] = _map(r, function (v) {
        return {'id': v.name, 'name': v.name};
      })
    });
  }

  const setCities = (value, key, setFieldValue, state) => {
    setFieldValue('cityOrTown', '');
    setFieldValue('city', '');
    getCounties(state, value).then(r => {
      query[key]['options'] = _map(r, function (v) {
        return {'id': v.name, 'name': v.name};
      })
    });
  }

  const onSubmit = async (valuesForm) => {
    const newValues = checkValuesToSend(valuesForm)
    let values = isOtherCounty ? newValues : valuesForm
    delete values.otherCity;
    delete values.otherCounty;
    let payload = {...values};
    payload = {...payload, is_manual:isOtherCounty ? true : false}
    const DATA = demographicInfo(payload)
    return handleSubmit(DATA);
    /* try {
      blockUI.current.open(true);
      let payload = {...values};
      payload = {...payload, is_manual:isOtherCounty ? true : false}
      const DATA = demographicInfo(payload)
      let res;
      if(employerId){
        const INFO = answerTempleteForEdit(DATA)
        res = await newQuestionService.questNext(INFO, hashToken, lang, true)
      }
      else{
        await newQuestionService.questStartNextNewApi(DATA, hashToken, lang)
        res = await newQuestionService.questStartNewApi(hashToken, lang)
      }
      updateQuestion(res?.data?.data[0]);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    } */
  };

  const handleSubmit = async (data) => {
    try {
      blockUI.current.open(true);
      let res;
      if(employerId){
        const INFO = answerTempleteForEdit(data);
        res = await newQuestionService.questNext(INFO, hashToken, lang, true);
      }
      else{
        await newQuestionService.questStartNextNewApi(data, hashToken, lang);
        res = await newQuestionService.questStartNewApi(hashToken, lang);
      }
      updateQuestion(res?.data?.data[0]);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const answerTempleteForEdit = (data) => {
    return {
      questionId: initial?.id,
      type: initial?.type,
      answer: data,
      groupId: initial?.groupId,
    }
  }

  const demographicInfo = (data) => {
    const INFO = {
      first_name: data?.firstName,
      middle_name: data?.middleName,
      last_name: data?.lastName,
      date_of_birth: data?.dateOfBirth,
      address: data?.currentAddress,
      email: data?.email,
      social_number: data?.socialSecurityNumber,
      state: data?.state,
      is_manual: data?.is_manual,
      county: data?.county,
      city: data?.city,
      zip_code: data?.zipCode,
      phone_number: data?.phoneNumber,
      opt_received: data?.name,
      //live_rrc: data?.doYouLiveInRuralRenewalCountyOrEmpowermentZone === 'yes' ? true : false,
      //live_ez: data?.doYouLiveInAnEmpowermentZoneAndAreAtLeastAge16ButNotYet18OnYourHiringDate === 'yes' ? true : false
    }
    return INFO
  }

  const checkValuesToSend = (valuesForm) => {
    let values = valuesForm
    values = {...values, city:values?.otherCity, county:values?.otherCounty}
    return values
  }

  const validationSchema = () => {
    const validationObj = {};
    query.forEach((item) => {
      let validationLang = (lang === 'es') ? item.spanishTitle : item.title;
      item.type === 'other_county' ? (validationObj[_camelCase(item.title)] = Yup.string().nullable()) :
      item.type === 'add_other_county' 
        ? (isOtherCounty ? (validationObj[_camelCase(item.title)] = Yup.string().required(`${validationLang} ${t("question.requiredCustom")}`)) : Yup.string().nullable().notRequired())
        : item.type === 'add_other_city' 
        ? (!isOtherCounty ? Yup.string().nullable().notRequired() : (validationObj[_camelCase(item.title)] = Yup.string().required(`${validationLang} ${t("question.requiredCustom")}`)))
        : isOtherCounty && (item?.code === 'Q13' || item?.code === 'Q14') ? Yup.string().nullable() :
      item.title === 'Middle name'
        ? (validationObj[_camelCase(item.title)] = Yup.string().nullable())
        : item.title === 'Email'
          ? (validationObj[_camelCase(item.title)] =
            Yup.string().email(t("question.validateEmail"))
              .nullable(false).required(`${validationLang} ${t("question.requiredCustom")}`))
          : item.title === 'Date of Birth'
            ? (validationObj[_camelCase(item.title)] = Yup.date()
                .required(`${validationLang} ${t("question.requiredCustom")}`)
                .typeError(t("question.basicDateTypeError"))
                .max(new Date(), t("question.basicDateTypeErrorMax"))
                .test("is-adult", t("question.basicDateTest"), (value) => {
                  if (value) {
                    const birthdate = new Date(value);
                    const today = new Date();
                    let age = today.getFullYear() - birthdate.getFullYear();
                    const monthDiff = today.getMonth() - birthdate.getMonth();
                    if (
                      monthDiff < 0 ||
                      (monthDiff === 0 && today.getDate() < birthdate.getDate())
                    ) {
                      age--;
                    }
                    return age >= 16;
                  }
                  return false;
                })
            )
            : item.type === 'basic_date' && item.isRequired
              ? (validationObj[_camelCase(item.title)] = Yup.date()
                  .required(t("question.requiredCustom"))
                  .max(new Date(), t("question.basicDateTypeErrorMax"))
                  .typeError(t("question.basicDateTypeError"))
              )
              : item.type === 'basic_date' && !item.isRequired
                ? (validationObj[_camelCase(item.title)] = Yup.date()
                    .nullable()
                    .max(new Date(), t("question.basicDateTypeErrorMax"))
                    .typeError(t("question.basicDateTypeError"))
                )
                : item.title === 'Zip Code'
                  ? (validationObj[_camelCase(item.title)] = Yup.string()
                      .matches(/^\d+$/, 'zip code must be only numbers')
                      .required(`${validationLang} ${t("question.requiredCustom")}`)
                      .min(5, t("question.validationDigits"))
                      .max(5, t("question.validationDigits"))
                  )
                  : item.type === 'yes_no'
                    ? (validationObj[_camelCase(item.title)] = Yup.string()
                        .required(t("question.yesNoRequired"))
                    ) : item.title === 'Phone Number'
                      ? (validationObj[_camelCase(item.title)] = Yup.string()
                          .required(`${validationLang} ${t('question.requiredCustom')}`)
                          .test('is-phone-number', 'Phone number must be 14 characters', (value) => {
                            if (value) {
                              return value.length === 14;
                            }
                            return false;
                          })
                      )
                      : item.code === 'Q58'
                        ? (validationObj[_camelCase(item.title)] = Yup.number().nullable()
                          .required(`${validationLang} ${t('question.requiredCustom')}`)
                          .test('is-more-than-zero', 'Has to be greater than 0 and less than 999.99', (value) => {
                            return Number(value) > 0 && Number(value) <= 999.99;
                          })) :
                        item.type === 'numerical' && item.code !== 'Q11'
                          ? (validationObj[_camelCase(item.title)] = Yup.number().nullable()
                            .required(`${validationLang} ${t('question.requiredCustom')}`))
                          : (validationObj[_camelCase(item.title)] = Yup.string().nullable()
                            .required(`${validationLang} ${t('question.requiredCustom')}`));
    });
    return Yup.object(validationObj);
  };

  useEffect(() => {
    if(initial?.isManual){
      setIsOtherCounty(true)
    }
  }, [initial])

  const handleChangeCheck = (e,setFieldValue) => {
    if(e.target.checked){
      setFieldValue('county', '');
      setFieldValue('city', '');
    }else{
      setFieldValue('otherCounty', '');
      setFieldValue('otherCity', '');
    }
    setIsOtherCounty(e.target.checked)
  }

  const returnLabelField = (q,questionLang) => {
    if(q?.type === 'other_county') return null
    if((q?.type === 'add_other_county' || q?.type === 'add_other_city') && !isOtherCounty)return null
    if((q?.code === 'Q13' || q?.code === 'Q14') && isOtherCounty)return null
    //if(q?.code === 'Q4')return null
    return (
      <p className='multiTitle'>{questionLang}: {q.isRequired ? (<span style={{color: "red"}}>*</span>): '' }</p>
    )
  }

  const disableCountyAndCity = (q, values) => {
    if(isOtherCounty && (q?.code === 'Q13' || q?.code === 'Q14') ? true : false)return true;
    if(q?.code === 'Q13' && !values.state)return true;
    if(q?.code === 'Q14' && !values.county)return true;
    return false;
  }

  return (
    <>
      <Formik
        initialValues={inputValues}
        validationSchema={validationSchema()}
        onSubmit={onSubmit}
      >
        {(props) => {
          const {
            values,
            handleBlur,
            handleChange,
            setFieldValue,
          } = props;
          return (
            <Form autoComplete='false' className='formCreateUser' noValidate>
              {query &&
                React.Children.toArray(
                  query.map((q, index) => {

                    let questionLang = (lang === 'es') ? q.spanishTitle : q.title

                    return (
                      <>
                        {returnLabelField(q,questionLang)}
                        {_eq(q.type, 'single_text') && !_eq(q.title, 'Phone Number') && (
                          <AppTextField
                            required
                            label={`${t("question.labelCustom")} ${questionLang.toLowerCase()}`}
                            variant='outlined'
                            id={`${q.id}`}
                            name={_camelCase(q.title)}
                            onBlur={handleBlur}
                            sx={styles.inputCustom}
                            type={_camelCase(q.title)}
                          />
                        )}
                      
                          {_eq(q.type, 'single_text') && _eq(q.title, 'Phone Number') && (
                          <AppTextField
                            required
                            label={`${t("question.labelCustom")} ${questionLang.toLowerCase()}`}
                            variant='outlined'
                            id={`${q.id}`}
                            name={_camelCase(q.title)}
                            onBlur={handleBlur}
                            sx={styles.inputCustom}
                            value={values.phoneNumber || ""}
                            onChange={(phone) => {
                              const formattedPhone = formatPhoneNumber(phone.target.value);
                              setFieldValue("phoneNumber", formattedPhone);
                            }}
                          />
                        )}
                        {_eq(q.type, 'basic_date') && (
                          <AppDatePicker
                            id={`${q.id}`}
                            name={_camelCase(q.title)}
                            onChange={(e) => handleChange(e, index, _camelCase(q.title))}
                            sx={styles.inputCustom}
                          />
                        )}
                        {_eq(q.type, 'numerical') && !_eq(q.title, 'Zip Code') && !_eq(q.title, 'Social Security Number') &&
                        !_eq(q.title, 'Hourly Starting Wage') &&
                        (
                          <AppTextField
                            label={t("question.labelDefault")}
                            variant="outlined"
                            id={`${q.id}`}
                            name={_camelCase(q.title)}
                            type={_camelCase(q.title) === 'socialSecurityNumber' ? "password" : "text" }
                            sx={styles.numerical}
                          />
                        )}
                         {_eq(q.type, 'numerical') && _eq(q.title, 'Zip Code') && (
                          <AppTextField
                            label={`${t("question.labelCustom")} ${questionLang.toLowerCase()}`}
                            variant='outlined'
                            id={`${q.id}`}
                            name={_camelCase(q.title)}
                            sx={styles.numerical}
                            type={"text"}
                            value={values.zipCode || ""}
                            onChange={(zip) => {
                              const formattedZip = formatZipCode(zip.target.value);
                              setFieldValue("zipCode", formattedZip);
                            }}
                          />
                        )}
                         {_eq(q.type, 'numerical') && _eq(q.title, 'Social Security Number') && (
                          <AppTextField
                            label={`${t("question.labelCustom")} ${questionLang.toLowerCase()}`}
                            variant='outlined'
                            id={`${q.id}`}
                            name={_camelCase(q.title)}
                            sx={styles.numerical}
                            type={"text"}
                            value={values.socialSecurityNumber || ""}
                            onChange={(e) => {
                              const ssn = getRawSSN(e.target.value);
                              const formattedSSN = formatSSN(ssn);
                              setFieldValue("socialSecurityNumber", formattedSSN);
                            }}
                          />
                        )}
                           {_eq(q.type, 'numerical') && _eq(q.title, 'Hourly Starting Wage') && (
                          <AppTextField
                            label={`${t("question.labelCustom")} ${questionLang.toLowerCase()}`}
                            variant='outlined'
                            id={`${q.id}`}
                            name={_camelCase(q.title)}
                            sx={styles.numerical}
                            value={values.hourlyStartingWage || ""}
                            onChange={(e) => {
                              const re = /^\d*\.?\d{0,2}$/;
                              if (e.target.value === "" || re.test(e.target.value))
                                handleChange(e);
                            }}
                          />
                        )}
                        {_eq(q.type, 'yes_no') && (
                          <AppCheckbox
                            sx={stylesChk.checkboxCtn} 
                            id={`${q.id}`}
                            name={_camelCase(q.title)}
                            options={[
                              {'label': `${(lang === 'es') ? 'Si' : 'Yes'}`, 'value': 'yes', 'disabled': false},
                              {'label': 'No', 'value': 'no', 'disabled': false}
                            ]}
                          />
                        )}
                        {_eq(q.type, 'state_federal') && (<>state_federal</>)}
                        {_eq(q.type, 'yes_no_unsure') && (<>yes_no_unsure</>)}
                        {isOtherCounty && (q?.code === 'Q13' || q?.code === 'Q14') ? null : _eq(q.type, 'single_list') && (
                          <AppSelect
                            id={`${q.id}`}
                            name={_camelCase(q.title)}
                            state={true}
                            onClick={(e) => handleChangeClick(e, _camelCase(q.title), values, setFieldValue)}
                            data={q.options}
                            autoFocus
                            fullWidth
                            sx={stylesState.selectCtn}
                            placeholder={'-- Select --'}
                            disabled={disableCountyAndCity(q, values)}
                            //disabled={isOtherCounty && (q?.code === 'Q13' || q?.code === 'Q14') ? true : false}
                          />
                        )}
                        {_eq(q.type, 'digital_signature') && (<>digital_signature</>)}
                        {q.type === 'other_county' && (
                            <FormControlLabel
                            checked={isOtherCounty}
                            disabled={false}
                            control={<Checkbox sx={{margin: '0px'}} color="success" onChange={(e) => handleChangeCheck(e, setFieldValue)} />}
                            label={questionLang}
                            labelPlacement="start"
                            sx={{display: 'flex', justifyContent: 'start', margin:0, gap: '10px', width: { md: '320px', xs: '100%' }}}
                            />
                        )}
                        {isOtherCounty ? _eq(q.type, 'add_other_county') && (
                          <AppTextField
                            required={isOtherCounty ? true : false}
                            label={`${t("question.labelCustom")} ${questionLang.toLowerCase()}`}
                            variant='outlined'
                            name={_camelCase(q.title)}
                            onBlur={handleBlur}
                            sx={styles.inputCustom}
                            type={_camelCase(q.title)}
                          /> 
                        ): null}
                        {isOtherCounty ? _eq(q.type, 'add_other_city') && (
                          <AppTextField
                            required={isOtherCounty ? true : false}
                            label={`${t("question.labelCustom")} ${questionLang.toLowerCase()}`}
                            variant='outlined'
                            name={_camelCase(q.title)}
                            onBlur={handleBlur}
                            sx={styles.inputCustom}
                            type={_camelCase(q.title)}
                          />
                        ) : null}
                      </>
                    );
                  })
                )}
              <Box>
                <Button color='inherit' type='submit'>
                  {t("question.btnNext")}
                </Button>
              </Box>
            </Form>
          )
        }
        }
      </Formik>
    </>
  );
};

export default MultiQuestion;