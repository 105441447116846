// @vendors
import React, { useEffect, useMemo, useState } from 'react'
import {
    Box,
    Button,
    Container,
    Paper,
    Typography,
} from '@mui/material'
import { useParams, useLocation } from 'react-router-dom';

// @assets
import { QustionVeteranStyles, Styles } from '../../../assets/css/questionnaire-style';
import logo from '../../../assets/images/logoDashboardPublic.svg';

// @context
import { useUI } from '../../../app/context/ui';

// @services
import QuestionnaireServiceNewApi from '../../../services/newApi/QuestionnaireServiceNewApi';
import { useTranslation } from 'react-i18next';
import { redirectToBulkhorn } from '../../../helpers/FunctionsUtils';

const SavedVeteranQuestion = () => {
    const styles = useMemo(() => Styles(), []);
    const style = useMemo(() => QustionVeteranStyles(), []);
    const newQuestionService = useMemo(() => new QuestionnaireServiceNewApi(), []);
    const [companyLogo, setCompanyLogo] = useState();
    const { blockUI } = useUI();
    const {hash} = useParams()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get('lang') || 'en';
    const { t} = useTranslation();
    const [initial, setInitial] = useState();

    const getLogo = async () => {
        try {
          const resp = await newQuestionService.callCompanyLogo(hash);
          setCompanyLogo(resp?.data?.data?.logo);
        } catch (e) {
          blockUI.current.open(false);
        }
    }

    const getStartInfo = async () => {
        try {
          blockUI.current.open(true);
          const resp = await newQuestionService.questStartNewApi(hash, lang);
          const data = resp?.data?.data[0];
          setInitial(data);
          blockUI.current.open(false);
        } catch (e) {
          blockUI.current.open(false);
        }
    }

    useEffect(() => {
        getStartInfo();
        getLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const nextPage = () => {
        if(initial?.obRedirectLink)return redirectToBulkhorn(initial?.obRedirectLink);
        return;
    }

  return (
    <>
        <Box sx={styles.logo}>
            {companyLogo 
                ? <img
                    src={companyLogo}
                    alt='dashboard public'
                    style={{
                        borderRadius: '5px',
                        objectFit: 'cover',
                        marginTop: '20px',
                        width: '200px',
                        maxHeight: '150px'
                    }}
                    />
                : <img src={logo} alt='dashboard public' width='250px' />
            }
        </Box>
        <Container>
            <Paper
                elevation={4}
                sx={style.paperVeterean}
            >
                <Box 
                    sx={{
                        display:"flex", 
                        flexDirection:"column", 
                        gap:{ xs: '0.5rem', md: '1rem' },
                        width: "100%",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        variant='h1'
                        sx={style.greatTilte}
                    >
                        {lang === "es" ? "Excelente!" : "Great!"}
                    </Typography>

                    <>
                        <Typography variant="body1" sx={style.subText} >{lang === "es" ? "La respuesta fue enviada exitosamente." : "The answer was sent succesfully"}</Typography>
                        {initial?.confirmationCode &&
                            <Typography variant="body1" sx={style.subText}>
                                {t("question.codeConfirmation")} {initial?.confirmationCode}.
                            </Typography>
                        }
            
                        {(!initial?.obRedirectLink) &&
                            <Typography variant="body1" sx={style.subText}>
                                {t("question.closeWindow")}
                            </Typography>
                        }

                        {(initial?.obRedirectLink) &&
                            <Button
                                variant='contained'
                                onClick={nextPage}
                                sx={{
                                    color: '#fff',
                                    backgroundColor: '#000',
                                    '&: hover': {backgroundColor: '#343434'},
                                    height: '40px'
                                }}
                            >
                                Next
                            </Button>
                        }
                    </>
                </Box>
            </Paper>
        </Container>
    </>
  )
}

export default SavedVeteranQuestion