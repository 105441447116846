/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { createContext, memo, useEffect, useMemo, useState } from 'react';
import { isEmpty as _isEmpty, isUndefined as _isUndefined } from 'lodash';
import { useLocation, useParams} from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import translationEN from './translation/translation_en.json';
import translationES from './translation/translation_es.json';

// @state
import { useUI } from '../../app/context/ui';

// @assets
import { Styles } from '../../assets/css/questionnaire-style';
import logo from '../../assets/images/logoDashboardPublic.svg';

// material/ui
import { Box, Container, Typography } from '../../components/shared/MaterialUI';

// @helpers
import AppHelper from '../../helpers/AppHelper';

// @components
import MatrixBox from './components/MatrixBox';
import SingleQuestion from './components/SingleQuestion';
import ThankYou from './components/ThankYou';
import GreatRb from "./components/GreatRb";

// @services
import QuestionnaireServiceNewApi from '../../services/newApi/QuestionnaireServiceNewApi';

i18n.use(initReactI18next).init({
  lng: 'en',
  resources: {
    en: {
      translation: translationEN
    },
    es: {
      translation: translationES
    }
  },
  interpolation: {
    escapeValue: false
  }
});

function Prescreening({ title }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get('lang') || 'en';
  const { companyId } = location.state ? location.state : 0;
  const [initial, setInitial] = useState({});
  const { hashToken, employerId, hashRb } = useParams();
  const { dialogUI, blockUI, snackbarUI } = useUI();
  const styles = useMemo(() => Styles(), []);
  const { t, i18n } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('lang') || 'en';
  const newQuestionService = useMemo(() => new QuestionnaireServiceNewApi(), []);
  const [companyLogo, setCompanyLogo] = useState();
  const [companyName, setCompanyName] = useState();
  const [messageUnsubscribed, setMessageUnsubscribed] = useState('');

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const getLogo = async () => {
    try {
      blockUI.current.open(true);
      const resp = await newQuestionService.callCompanyLogo(hashToken);
      setCompanyLogo(resp?.data?.data?.logo);
      setCompanyName(resp?.data?.data?.name);
      } catch (e) {
      blockUI.current.open(false);
    }
  }

  const questionnaireStart = async (hash) => {
    try {
      blockUI.current?.open(true);
      const response = await newQuestionService.questStartNewApi(hash, lang, employerId);
      const data = response?.data?.data[0];
      updateQuestion(data);
      blockUI.current?.open(false);
      return data;
    } catch (e) {
      blockUI.current?.open(false);
      if (e?.response?.data?.message === 'Session has expired.') {
        e.response.data.message = 'The questionnaire has already been processed'
        AppHelper.checkError(e, snackbarUI);
      }
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const settings = {
    confirm: true,
    btn: {
      confirm: 'Close',
      close: '',
    },
    onConfirm: () => {
      dialogUI.current.close();
    },
    styles: { ...styles.dialog },
  };

  const updateQuestion = (res) => {
    if(res === 'You are unsubscribed')return setMessageUnsubscribed(res);
    setMessageUnsubscribed('');
    let payload = {...res };
    if (!_isEmpty(res?.answer)) {
      payload = { ...payload, response: res?.answer }
    }
    if (res?.status && (res?.status === 'finished' || res?.status === 'completed')) {
      payload = { ...res, message: res?.message, status: res?.status }
    }
    const newPayload = addOtherCounty(payload)  
    setInitial(newPayload);
  }

  const addOtherCounty = (payload) => {
    const otherCounty = {
      type: 'other_county',
      isRequired: false,
      title: 'My county or city is not listed',
      spanishTitle: 'My condado o ciudad no esta listado'
    }
    const addCounty = {
      type: 'add_other_county',
      isRequired: false,
      title: 'Other county',
      spanishTitle: 'Otro condado'
    }
    const addCity = {
      type: 'add_other_city',
      isRequired: false,
      title: 'Other city',
      spanishTitle: 'Otra ciudad'
    }
    if(payload?.title === "Name (first, MI, Last)"){
      const hasOptionOtherCounty = payload?.questions?.find(x => x?.type === 'other_county')
      if(!hasOptionOtherCounty?.type){
       payload?.questions?.splice(8,0,otherCounty)
       payload?.questions?.splice(9,0,addCounty)
       payload?.questions?.splice(11,0,addCity)
        return payload
      }
      return payload
    }
    return payload
  }

  useEffect(() => {
    getLogo()
    blockUI.current.open(true);
    const init = async () => {
      document.title = `RockerBox - ${title}`;
      if (Boolean(companyId)) return await questionnaireStart(hashToken, { employer_id: companyId });
      if (_isUndefined(initial?.status) && !Boolean(companyId)) return await questionnaireStart(hashToken);
    }
   
    if(document.title === ''){
      init();
    }
  }, [companyId, hashToken, hashRb]);

  useEffect(() => {
    changeLanguage(langParam);
  }, [i18n.language]);

  const props = {
    hashToken,
    hashRb,
    companyId,
    employerId,
    settings,
    lang,
    t,
    initial,
    updateQuestion,
    companyLogo,
    companyName
  };

  return (
    <>
      <Box sx={styles.logo}>
        {companyLogo 
          ? <img
              src={companyLogo}
              alt='dashboard public'
              style={{
                borderRadius: '5px',
                objectFit: 'cover',
                marginTop: '20px',
                width: '200px',
                maxHeight: '150px'
              }}
            />
          : <img src={logo} alt='dashboard public' width='250px' />
        }
      </Box>
      <QuestionnaireContext.Provider value={props}>
        <Container>
          {messageUnsubscribed
          ? <Box sx={styles.containerUnsubscribed}>
              <Typography sx={styles.textUnsubscribed}>{messageUnsubscribed}</Typography>
            </Box>
          : null
          }
          {initial && initial.type === 'matrix_box'
            ? (<MatrixBox />)
            : initial && initial.type && initial.type !== 'matrix_box'
              ? ( <SingleQuestion /> )
              : initial?.status && (initial?.status === 'completed' || initial?.status === 'Completed' )
                ? ( <ThankYou id={companyId} /> )
                : initial?.subStatus === 4
                  ? <GreatRb />
                  : null}
        </Container>
      </QuestionnaireContext.Provider>
    </>
  );
}

export const QuestionnaireContext = createContext({});
export default memo(Prescreening);