/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { useHistory, useParams, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// @assets
import { EmployerStyles } from '../../../assets/css/employer-style';
import logo from '../../../assets/images/logoDashboardPublic.svg';
import { Styles } from '../../../assets/css/questionnaire-style';

// @context
import { useUI } from '../../../app/context/ui';

// @services
import QuestionnaireServiceNewApi from '../../../services/newApi/QuestionnaireServiceNewApi';

// @helpers
import AppHelper from '../../../helpers/AppHelper';
import { formDataUploadEmployees, showTextDriverLicense } from '../../../helpers/FunctionsUtils';

const UploadDriverLicenseFromEmail = () => {

  const styles = useMemo(() => EmployerStyles(), []);
  const styleLogo = useMemo(() => Styles(), []);
  const { blockUI, snackbarUI } = useUI();
  const newQuestionService = useMemo(() => new QuestionnaireServiceNewApi(), []);
  const history = useHistory();
  const videoRef = useRef(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const photoRef = useRef(null);
  const {hash} = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lang = queryParams.get('lang') || 'en';
  const [companyLogo, setCompanyLogo] = useState();

  const getVideo = () => {
    setIsCameraOn(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error('Error accessing camera: ', err);
      });
  };
  
  const takePhoto = () => {
    const width = 320;
    const height = 240;
    let video = videoRef.current;
    let photo = photoRef.current;
    photo.width = width;
    photo.height = height;
    let ctx = photo.getContext('2d');
    ctx.drawImage(video, 0, 0, width, height);
    photo.toBlob((blob) => {
      if (blob) {
        uploadPhoto(blob);
      }
    },'image/jpeg', 0.9);
  };

  const handleClickFile = () => {
    const file = document.getElementById('upload-input-license')
    file?.click()
  }
  
  const uploadPhoto = (blob) => {
    if (blob) {
      const formData = new FormData();
      formData.append('file', blob, 'photo.jpg');
      handleSubmit(formData);
    }
  };
  
  const uploadFile = (e) => {
    if (e.target.files[0]) {
      const file = formDataUploadEmployees(e.target.files[0]);
      handleSubmit(file, e);
    }
  };

  const handleSubmit = async (value, e) => {
    try {
      blockUI.current.open(true);
      await newQuestionService.uploadDriverLicense(hash, value);
      document.title = '';
      blockUI.current.open(false);
      return navigateTo();
    } catch (err) {
      blockUI.current.open(false);
      AppHelper.checkError(err, snackbarUI);
      if(e)e.target.value = '';
    }
  }

  const handleSkip = async() => {
      try {
        blockUI.current.open(true);
        await newQuestionService.withoutLicense(hash, 'I DONT HAVE IT NOW');
        blockUI.current.open(false);
        return navigateTo();     
      } catch (error) {
        blockUI.current.open(false);
        AppHelper.checkError(error, snackbarUI);
      }
  }

  const getStartInfo = async () => {
    try {
      blockUI.current.open(true);
      const resp = await newQuestionService.questStartNewApi(hash, lang);
      const data = resp?.data?.data[0];
      blockUI.current.open(false);
      if(data?.hasDriverLicenseFile)return navigateTo();
    } catch (e) {
      blockUI.current.open(false);
    }
  }
    
  const getLogo = async () => {
    try {
      blockUI.current.open(true);
      const resp = await newQuestionService.callCompanyLogo(hash);
      setCompanyLogo(resp?.data?.data?.logo);
      blockUI.current.open(false);
      } catch (e) {
      blockUI.current.open(false);
    }
  }

  useEffect(() => {
    getStartInfo();
    getLogo();
  }, [])

  const navigateTo = () => {
    const params = lang ? `${hash}?lang=${lang}` : `${hash}`;
    return history.push(`/confirmation-code-answer/${params}`);
  }

  return (
    <> 
        <Box sx={styleLogo.logo}>
            {companyLogo 
            ? <img
                src={companyLogo}
                alt='dashboard public'
                style={{
                    borderRadius: '5px',
                    objectFit: 'cover',
                    marginTop: '20px',
                    width: '200px',
                    maxHeight: '150px'
                }}
                />
            : <img src={logo} alt='dashboard public' width='250px' />
            }
        </Box>

        <Paper
            elevation={4}
            sx={{
            margin: {xs: '20px 10px 10px 10px', md: '50px 50px 50px 50px'},
            width: '100%',
            }}
        >
            <Grid
                sx={{
                ...styles.containerDownload,
                minHeight: "30vh",
                maxHeight: "70vh",
                gap: '20px',
                overflow: 'auto'
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                    marginTop: '30px',
                    fontSize: '19px',
                    lineHeight: '25px',
                    }}
                >
                    {showTextDriverLicense(lang)}
                </Typography>
                <Box
                    sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                    gap: {xs: '20px', md: '0px'},
                    justifyContent: 'space-around',
                    margin: '10px 0px',
                    width: '100%',
                    }}
                >
                    <Button
                    variant='contained'
                    onClick={handleSkip}
                    sx={{
                        color: '#fff',
                        backgroundColor: '#000',
                        '&: hover': {backgroundColor: '#343434'},
                        height: '40px'
                    }}
                    >
                    I don't have it now
                    </Button>

                    <Button
                    variant='contained'
                    onClick={getVideo}
                    sx={{
                        color: '#fff',
                        backgroundColor: '#000',
                        '&: hover': {backgroundColor: '#343434'},
                        height: '40px'
                    }}
                    >
                    Take photo
                    </Button>

                    <Button
                    variant='contained'
                    onClick={handleClickFile}
                    sx={{
                        color: '#fff',
                        backgroundColor: '#000',
                        '&: hover': {backgroundColor: '#343434'},
                        height: '40px'
                    }}
                    >
                    upload image
                    </Button>
                </Box>
                    
                {isCameraOn && 
                    <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', md: 'column'},
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: {xs: '0px', md: '20px'},
                    }}
                    >
                    <video ref={videoRef} style={{width: '250px', height: '250px'}}></video>
                    <Button
                        variant='contained'
                        onClick={takePhoto}
                        sx={{
                        color: '#fff',
                        backgroundColor: '#000',
                        '&: hover': {backgroundColor: '#343434'},
                        height: '40px'
                        }}
                    >
                        save
                    </Button>
                    <canvas ref={photoRef} style={{display: 'none'}}></canvas>
                    </Grid>
                }

                <input
                    type='file'
                    accept="image/*"
                    id='upload-input-license'
                    style={{display: 'none'}}
                    onChange={(e) => uploadFile(e)}
                />
            </Grid>
        </Paper>
    </>
  )
}

export default UploadDriverLicenseFromEmail