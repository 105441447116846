/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'

// @assets
import { TicketStyles } from '../../../assets/css/general-style';

// @context
import { useUI } from '../../../app/context/ui';

// @service
import TicketService from '../../../services/newApi/TicketServiceNewApi';

// @helpers
import AppHelper from '../../../helpers/AppHelper';
import { isValidUrl } from '../../../helpers/FunctionsUtils';

const TicketDetail = ({row}) => {
    const style = useMemo(() => TicketStyles(), []);
    const { blockUI, snackbarUI } = useUI();
    const ticketService = useMemo(() => new TicketService(), []);
    const [ticketDetail, setTicketDetail] = useState();

    const getTicketById = async (params) => {
        blockUI.current?.open(true);
        try {
            const response = await ticketService.getTicketDetail(row?.id);
            setTicketDetail(response?.data?.data);
            blockUI.current?.open(false);
        } catch (error) {
          handleError(error);
        }
    }

    const handleError = useCallback((error) => {
        AppHelper.checkError(error, snackbarUI);
        blockUI.current?.open(false);
      },
      [snackbarUI, blockUI]
    );

    useEffect(() => {
        getTicketById();
    }, [])

  return (
    <Grid
        container
        spacing={2}
        sx={style.container}
    >
        <Grid item xs={12} md={6} xl={4}>
            <Typography sx={style.titleHeader}>Jira Link</Typography>
            <Typography
                sx={{ ...style.subTitleHeader, ...style.subTitleHeaderHover }}
                onClick={() => isValidUrl(ticketDetail?.jiraLink) ? window.open(ticketDetail?.jiraLink, '_blank') : null}
            >
                {ticketDetail?.jiraLink ?? ''}
            </Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4}>
            <Typography sx={style.titleHeader}>Screen issue</Typography>
            <Typography
                sx={{ ...style.subTitleHeader, ...style.subTitleHeaderHover }}
                onClick={() => isValidUrl(ticketDetail?.screenIssue) ? window.open(ticketDetail?.screenIssue, '_blank') : null}
            >
               {ticketDetail?.screenIssue ?? ''}
            </Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Function impact</Typography>
            <Typography sx={style.subTitleHeader}>
                {ticketDetail?.functionImpact ?? ''}
            </Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Location issue</Typography>
            <Typography sx={style.subTitleHeader}>
            {ticketDetail?.locationIssue ?? ''}
            </Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Responsible Email</Typography>
            <Typography sx={style.subTitleHeader}>
            {ticketDetail?.responsibleEmail ?? ''}
            </Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Client name</Typography>
            <Typography sx={style.subTitleHeader}>
            {ticketDetail?.clientName ?? ''}
            </Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Employee name</Typography>
            <Typography sx={style.subTitleHeader}>
            {ticketDetail?.employeeName ?? ''}
            </Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Employee email</Typography>
            <Typography sx={style.subTitleHeader}>
            {ticketDetail?.employeeEmail ?? ''}
            </Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={4} >
            <Typography sx={style.titleHeader}>Data field affect</Typography>
            <Typography sx={style.subTitleHeader}>
                {ticketDetail?.dataFieldAffect ?? ''}
            </Typography>
        </Grid>

        <Grid item xs={12} md={6} xl={6} >
            <Typography sx={style.titleHeader}>Description</Typography>
            <Typography sx={style.subTitleHeader}>
            {ticketDetail?.description ?? ''}
            </Typography>
        </Grid>
        
        <Grid item xs={12} md={6} xl={6} >
            <Typography sx={style.titleHeader}>Response from dev</Typography>
            <Typography sx={style.subTitleHeader}>
            {ticketDetail?.responseFromDev ?? ''}
            </Typography>
        </Grid>
    </Grid>
  )
}

export default TicketDetail