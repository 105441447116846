// @vendors
import React, { useContext, useMemo, useState } from 'react'
import sx from "mui-sx";
import { useHistory } from 'react-router-dom';

// @components material
import {
    Box,
    Button,
    Collapse,
    EditIcon,
    ExpandMoreIcon,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    Tooltip,
} from '../../../components/shared/MaterialUI';
import EditNoteIcon from '@mui/icons-material/EditNote';

// @assets
import { UserManagementStyle } from '../../../assets/css/employeeTable-style';
import { GeneralStyles } from '../../../assets/css/general-style';

// @components
import TicketDetail from './TicketDetail';
import ResponseDialog from './ResponseDialog';

// @constants
import { ROUTENAME } from '../../../navigation/RouteName';

// @helpers
import { convertDateTolocaleTime, MenuItemProps } from '../../../helpers/FunctionsUtils';
import AppHelper from '../../../helpers/AppHelper';

// @service
import TicketService from '../../../services/newApi/TicketServiceNewApi';

// @store
import { useUI } from '../../../app/context/ui';
import { TicketContext } from '../TicketManagement';
import { useSelector } from 'react-redux';

const TicketRow = ({row}) => {
    const generalStyle = useMemo(() => GeneralStyles(), []);
    const style = useMemo(() => UserManagementStyle(), []);
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const history = useHistory();
    const { blockUI, snackbarUI } = useUI();
    const ticketService = useMemo(() => new TicketService(), []);
    const {handleMapResponse} = useContext(TicketContext);
    const hasDevPanelAccess = useSelector((state) => state?.user?.hasDevPanelAccess);
    const listStatus = [
        {id:1, value:'Open'},
        {id:3, value:'Processing'},
        {id:2, value:'Resolved'},
    ];

    const handleOpen = async () => {setOpen(!open);}

    const bgColorChip = () => {
        if(row?.statusId === 1)return '1px solid #d32f2f';
        if(row?.statusId === 3)return '1px solid #f57c00';
        return '1px solid #388e3c';
    }

    const handleClickOpen = () => setOpenModal(!openModal);

    const handleChange = async (e) => {
        const value = e.target.value;
        try {
            blockUI.current?.open(true);
            await ticketService.updateStatus(row.id, value);
            handleMapResponse();
        } catch (error) {
            AppHelper.checkError(error, snackbarUI);
            blockUI.current?.open(false);
        }
    }

  return (
    <>
        <TableRow>
            <TableCell
                scope="row"
                sx={sx(
                    generalStyle.tableCell,
                    { condition: open, sx: generalStyle.borderNone },
                    { condition: !open, sx: generalStyle.bottomBorder }
                )}
            >
                <IconButton onClick={handleOpen}>
                    <ExpandMoreIcon />
                </IconButton>
            </TableCell>

            <TableCell
                scope="row"
                sx={{...generalStyle.tableCell, textAlign: 'left', paddingLeft: '10px'}}
            >
                {row?.name}
            </TableCell>
            
            <TableCell
                scope="row"
                sx={{
                    ...generalStyle.tableCell,
                    ...generalStyle.tableColumnHide,
                    textAlign: 'left'
                }}
            >
                {convertDateTolocaleTime(row?.createdAt)}
            </TableCell>

            <TableCell
                scope="row"
                sx={{
                    ...generalStyle.tableCell,
                    ...generalStyle.tableColumnHide,
                    textAlign: 'left',
                }}
            >
                {row?.priority}
            </TableCell>
            
            <TableCell
                scope="row"
                sx={{...generalStyle.tableCell, textAlign: 'left'}}
            >
                <Box>
                    <Select
                        id="priority"
                        name='priority'
                        value={row?.statusId}
                        sx={{
                            width: '130px',
                            height: '40px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: bgColorChip(),
                            },
                        }}
                        onChange={(e) => {handleChange(e);}}
                        MenuProps={MenuItemProps}
                    >
                        {listStatus?.map((item, index) => (
                            <MenuItem
                                value={item.id}
                                key={index}
                            >
                                {item.value}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </TableCell>

            <TableCell
                sx={{
                    ...generalStyle.tableCell,
                    ...generalStyle.tableColumnHide
                }}
            >
            <Grid sx={style.containerButtons}>
                {hasDevPanelAccess &&
                    <Tooltip
                        title={<p style={{ fontSize: "16px", margin: "0" }}>Response from devs</p>}
                        arrow
                    >
                        <Button
                            variant="contained"
                            onClick={handleClickOpen}
                            sx={style.btnEdit}
                            children={<EditNoteIcon sx={{fontSize: "2rem"}} />}
                        />
                    </Tooltip>
                }
                <Tooltip
                    title={<p style={{ fontSize: "16px", margin: "0" }}>Edit ticket</p>}
                    arrow
                >
                    <Button
                        variant="contained"
                        onClick={() => history.push(`${ROUTENAME.editTicket}/${row?.id}`, {id: row.id})}
                        sx={style.btnEdit}
                        children={<EditIcon sx={{fontSize: "1.8rem"}} />}
                    />
                </Tooltip>
            </Grid>
            </TableCell>      
        </TableRow>

        <TableRow>
            <TableCell
                sx={sx(
                    generalStyle.tableCell,
                    { padding: "0"},
                    { condition: !open, sx: generalStyle.borderNone },
                    { condition: open, sx: generalStyle.bottomBorder }
                )}
                colSpan={12}
            >
                <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    sx={{ width: '100%' }}
                >
                    <TicketDetail row={row} />
                </Collapse>
            </TableCell>
        </TableRow>
        <ResponseDialog
            open={openModal}
            handleCloseModal={handleClickOpen}
            id={row.id}
        />
    </>
  )
}

export default TicketRow